export enum AnimalHint {
  TREATMENT_VOUCHER = 'treatment_voucher',
  BASIC_IMMUNIZATION = 'basic_immunization',
  OUT_OF_SECURITY = 'out_of_security',
  BECOME_A_SPONSOR = 'become_asponsor',
  SHOW_DONATE_NOW = 'show_donate_now',
}

export function getAnimalHintKey(value: AnimalHint): string | undefined {
  return Object.keys(AnimalHint).find((key) => AnimalHint[key as keyof typeof AnimalHint] === value);
}