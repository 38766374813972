import {createStore} from '@ngneat/elf';
import {
  withEntities,
  setEntities,
  addEntities,
  updateEntities,
  withActiveId,
  selectActiveEntity,
  setActiveId
} from '@ngneat/elf-entities';
import {withRequestsCache, withRequestsStatus} from '@ngneat/elf-requests';
import {Injectable} from "@angular/core";
import {map} from "rxjs";

export interface User {
  id: number;
  name: string;
  token: string;
  roles: string[];
  defaultAnimalShelter: {
    id: number;
  } | null;
}

const storeName = 'users';

export const store = createStore(
  {name: storeName},
  withEntities<User>(),
  withActiveId(),
  withRequestsCache<typeof storeName>(),
  withRequestsStatus<typeof storeName>()
);

@Injectable({providedIn: 'root'})
export class UserRepository {
  activeUser$ = store.pipe(selectActiveEntity());

  addUser(user: User) {
    store.update(addEntities(user));
  }

  updateUser(id: User['id'], user: Partial<User>) {
    store.update(updateEntities(id, user));
  }

  getActiveUser() {
    return store.pipe(selectActiveEntity());
  }

  setActiveUserId(id: User['id']) {
    store.update(setActiveId(id));
  }

  removeAllUsers() {
    store.update(setEntities([]));
  }

  getAnimalShelterId() {
    return store.pipe(selectActiveEntity()).pipe(map(user => {
      if (!user) {
        return null;
      }

      return user.defaultAnimalShelter?.id;
    }));
  }
}