<div class="card">
  <!-- <div class="card-header flex-row-reverse" *ngIf="!isEditingMode">
    <button class="p-button p-button-sm" (click)="toggleEditDialog()">
      Bilder bearbeiten
    </button>
  </div> -->
  <div class="grid gap-8 justify-content-center align-items-center p-6">
    <div class="col-3 max-w-20rem" *ngIf="!isEditingMode">
      <app-image-upload [animal]="animal"></app-image-upload>
    </div>
    <div class="col-3 max-w-20rem" *ngFor="let image of images">
      <div class="card flex flex-column min-h-full max-h-30rem">
        <div class="text-center p-3 flex-grow-1">
          <div class="blurred-image-container" [class.cursor-pointer]="!isEditingMode" (click)="openDialog(image)">
            <div class="blurred flex align-items-center justify-content-center">
              <app-animal-image-container [animal]="animal" [id]="image.id" [variant]="'full'"></app-animal-image-container>
            </div>
          </div>
        </div>
        <div class="flex flex-row flex-wrap gap-3 align-items-center justify-content-center icon-container">
        <div class="flex flex-row flex-wrap gap-3 align-items-center justify-content-center icon-container">
          <div class="icon-wrapper" [ngClass]="image.preview ? 'active' : ''"
            (click)="clickTogglePreview(image)"
            pTooltip="{{ 'tooltip.togglePreview' | transloco }}" 
            tooltipPosition="top"
          >
            <i class="pi pi-image cursor-pointer"></i>
          </div>
          <div class="icon-wrapper" [ngClass]="image.syncWithSharedFolder ? 'active' : ''"
            (click)="clickFileExport(image)"
            pTooltip="{{ 'tooltip.syncWithSharedFolder' | transloco }}" 
            tooltipPosition="top"
          >
            <i class="pi pi-file-export cursor-pointer"></i>
          </div>
          <p-confirmDialog [key]="'deleteImage' + image.id"/>
          <div
            class="icon-wrapper delete"
            [ngClass]="selectedImgToDelete.includes(image.id) ? 'delete' : ''"
            (click)="deleteImage(image)"
            pTooltip="{{ 'tooltip.delete' | transloco }}" 
            tooltipPosition="top"
          >
            <i class="pi pi-trash cursor-pointer"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-3">
    <p-messages 
      [value]="[{ severity: 'info', summary: 'messages.info' | transloco, detail:  'messages.sharePointSync' | transloco  }]" 
      [enableService]="false" 
      [closable]="false" />
  </div>
</div>
<p-dialog
  [(visible)]="displayDialog"
  [modal]="true"
  [style]="{ width: '50vw', height: '75vh' }"
  [closable]="true"
  [draggable]="false"
  [resizable]="false"
>
  <div class="flex flex-wrap justify-content-center align-items-center h-full w-full relative">
      <app-animal-image-container [animal]="animal" [id]="selectedImage?.id" [variant]="'full'"></app-animal-image-container>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-end">
      <button
        class="p-button p-button-secondary"
        icon=""
        (click)="displayDialog = false"
      >
        <i class="pi pi-times mr-1"></i>
        {{ 'modal.cancel' | transloco }}
      </button>
    </div>
  </ng-template>
</p-dialog>
