export enum AnimalWhereaboutsType {
  DIED = 'died',
  ADOPTED = 'adopted',
  BACK_WITH_OWNER = 'back-with-owner',
  PASSED_ON = 'passed-on',
  RAN_AWAY = 'ran-away',
  RELEASED_INTO_WILD = 'released-into-wild',
  ADOPTED_ON_TRIAL = 'adopted-on-trial',
  OUTDOOR_ANIMAL = 'outdoor-animal',
  FOSTER_HOME = 'foster-home',
  EUTHANASIA = 'euthanasia'
}