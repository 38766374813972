import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap, filter, take, first } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { AnimalRepository } from 'src/app/modules/animals/states/animal.repository';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private tokenService: TokenService,
    private _animalRepository: AnimalRepository
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.tokenService.getAccessToken() ? this.checkTokenAndProceed(state) : this.redirectToLogin(state);
  }

  private checkTokenAndProceed(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const token = this.tokenService.getAccessToken();

    if (token && this.tokenService.isTokenExpired(token)) {
      return this.tokenService.renewToken().pipe(
        switchMap(newToken => {
          if (newToken) {
            return this.executeGuardLogic(state);
          } else {
            return this.redirectToLogin(state);
          }
        })
      );
    } else if (token) {
      return this.executeGuardLogic(state);
    } else {
      return this.redirectToLogin(state);
    }
  }

  private executeGuardLogic(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this._animalRepository.dataLoaded$.pipe(
      switchMap(dataLoaded => {
        if (!dataLoaded) {
          return this._animalRepository.animals$.pipe(
            filter(animals => animals.length > 0),
            first(),
            switchMap(animals => this.checkAccess(animals, state))
          );
        }
        return this._animalRepository.animals$.pipe(
          first(),
          switchMap(animals => this.checkAccess(animals, state))
        );
      })
    );
  }

  private checkAccess(animals: any[], state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isLoggedIn$.pipe(
      filter(val => val !== null),
      take(1),
      switchMap(isLoggedIn => {
        if (!isLoggedIn) {
          return this.redirectToLogin(state);
        } else {
          if (state.url.startsWith('/tiere/')) {
            const urlSegments = state.url.split('/');
            const id = +urlSegments[urlSegments.length - 1];
            if (isNaN(id) || !animals.some(animal => Number(animal.id) === id)) {
              return this.redirectToLogin(state);
            }
            return of(true);
          }
          return of(true);
        }
      })
    );
  }

  private redirectToLogin(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return of(false);
  }
}
