export enum AnimalType {
  DOG = 'dog',
  CAT = 'cat',
  FERRET = 'ferret',
  BIRD = 'bird',
  RODENT = 'rodent',
  HARE = 'hare',
  TURTLE = 'turtle',
  MARTEN = 'marten',
  SPIDER = 'spider',
  LIZARD = 'lizard',
  TENREC = 'tenrec',
  SNAKE = 'snake',
  HORSE = 'horse',
  DONKEY = 'donkey',
  ALPACA = 'alpaca',
  BEAR = 'bear',
  SHEEP = 'sheep',
  PIG = 'pig',
  GOAT = 'goat',
  COW = 'cow',
  ELK = 'elk',
}