import { HelperService } from 'src/app/shared/services/helper.service';
import { AnimalWhereaboutsType } from '../models/animal-wherabout-types.enum';
import { AnimalWhereaboutType } from '../models/animal-whereabout-type.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AnimaleWhereAboutService {
  constructor(
    private _helperService: HelperService
  ) { }


  getAnimalWhereaboutTypeOptions(): AnimalWhereaboutType[] {
    return this._helperService.getEnumOptions(AnimalWhereaboutsType, 'animal.whereabout');
  }

}