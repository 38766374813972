import {Component, OnInit} from '@angular/core';
import {User, UserRepository} from "../states/user.repository";

@Component({
  selector: 'app-dashboard',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  activeUser: User | undefined

  constructor(
    private userRepository: UserRepository,
  ) {
  }

  ngOnInit() {
    this.userRepository.getActiveUser().subscribe((user) => {
      this.activeUser = user;
    });
  }
}
