import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {AnimalModel} from '../models/animal.model';
import {combineLatest, take, takeWhile} from 'rxjs';
import {AnimalOriginModel} from '../models/animal-origin.model';
import {CompetentAuthoritiesRepository} from 'src/app/shared/states/competent-authorities.repository';
import {AnimalEditService} from '../services/animal-edit.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {AnimalOriginRepository} from '../states/animal-origin.repository';
import {AnimalOriginReasonForTransfer} from "../models/animal-origin-reason-for-transfer.enum";
import {CompetentAuthorityModel} from 'src/app/shared/models/competent-authority.model';
import {ContactRepository} from "../../contact/states/contact.repository";
import {ContactModel} from "../../contact/models/contact.model";
import {HelperService} from "../../../shared/services/helper.service";
import { PersonTitleModel } from 'src/app/shared/models/person-title.model';

@Component({
  selector: 'app-animal-details-origin',
  templateUrl: './animal-details-origin.component.html',
  styleUrls: ['./animal-details-origin.component.scss']
})
export class AnimalDetailsOriginComponent implements OnInit, OnDestroy {
  @Input() animal!: AnimalModel | undefined;
  @Output() formsValid: EventEmitter<boolean> = new EventEmitter();
  alive: boolean = true;
  animalOrigItems: { [id: string]: AnimalOriginModel } = {} as { [id: string]: AnimalOriginModel };
  cityName: { [id: string]: string | undefined } = {};
  isEditingMode: boolean = false;
  openTab: { [tab: string]: boolean | string } = {};
  formGroupOrigins: { [id: string]: FormGroup } = {};
  originReasonForTransfer: { name: string; value: string; }[] = [];
  originSubmittedPersonSalutation: PersonTitleModel[] = [];
  allFormsValid: boolean = true;
  contacts: { [id: string]: ContactModel } = {};
  originWasGiven: { [originId: string]: boolean } = {};
  tempOriginWasGiven: { [originId: string]: boolean } = {};
  originCompetentAuthorityOptions: CompetentAuthorityModel[] = [];
  clearContactDialog: boolean = false;
  pendingOriginId: string = '';
  pendingSwitchState: boolean = false;

  constructor(
    private competentAuthoritiesRepository: CompetentAuthoritiesRepository,
    private _animalEditService: AnimalEditService,
    private _translateService: TranslocoService,
    private formBuilder: FormBuilder,
    private _animalOriginRepository: AnimalOriginRepository,
    private cdr: ChangeDetectorRef,
    private _contactRepository: ContactRepository,
    private _helperService: HelperService
  ) {
    //Silence is golden
  }

  ngOnInit(): void {
    this._animalEditService.editDialogVisible.pipe(take(1)).subscribe((openTab: {
      [tab: string]: boolean | string
    }) => {
      this.openTab = openTab;
      this.isEditingMode = openTab && Object.keys(openTab).length > 0;
    });

    this.originSubmittedPersonSalutation = this._helperService.getSalutationOptions();

    combineLatest([
      this._animalOriginRepository.getByAnimalId(this.animal?.id),
      this.competentAuthoritiesRepository.getCompetentAuthorities(),
    ]).pipe(takeWhile(() => this.alive)).subscribe(([animalOrigins, competentAuthorities]) => {
      if (competentAuthorities.length) {
        this.originCompetentAuthorityOptions = competentAuthorities;
        competentAuthorities.forEach((competentAuthority: CompetentAuthorityModel) => {
          this.cityName[competentAuthority.id] = competentAuthority.name;
        });
      }

      if (animalOrigins.length) {
        this.setAnimalOrigins(animalOrigins);
      }
    });
  }

  setAnimalOrigins(animalOrigins: AnimalOriginModel[]) {
    this.formGroupOrigins = {};
    this.setReasonsForTransferOptions();
  
    const processAnimalOrigin = (animalOrigin: AnimalOriginModel, contact?: ContactModel) => {
      if (!animalOrigin.id) {
        return;
      }
      const id = (animalOrigin.id).toString() as string;
      this.animalOrigItems[id] = JSON.parse(JSON.stringify(animalOrigin));
      if (contact) {
        this.contacts[contact.id] = contact;
        this.animalOrigItems[id].contact = contact;
        this.wasGivenChanged({checked: this._helperService.checkAnyContactData(contact)}, id);
      }
      this.formGroupOrigins[id] = this.createFormGroup(this.animalOrigItems[id]);
  
      const selectedOptionReasonTransfer = this.originReasonForTransfer.find(option => option.value === (this.animalOrigItems[id]?.reasonForGiving ?? '')) || null;
      this.formGroupOrigins[id].controls['reasonForTransfer'].setValue(selectedOptionReasonTransfer);
  
      this.formGroupOrigins[id].valueChanges.subscribe(() => {
        this.allFormsValid = true;
        Object.values(this.formGroupOrigins).forEach((form: FormGroup) => {
          if (form.status === 'INVALID') {
            this.allFormsValid = false;
          }
        });
        this.formsValid.emit(this.allFormsValid);
      });
    };
  
    animalOrigins.forEach((animalOrigin: AnimalOriginModel) => {
      if (animalOrigin.contact && animalOrigin.contact.id) {
        this._contactRepository.getById(animalOrigin.contact.id).pipe(take(1)).subscribe((contact: ContactModel) => {
          processAnimalOrigin(animalOrigin, contact);
        });
      } else {
        processAnimalOrigin(animalOrigin);
      }
    });
  
    this.cdr.detectChanges();
  }
  

  createFormGroup(animalOrigin: AnimalOriginModel): FormGroup {
    const formGroupConfig: { [key: string]: FormControl } = {
      competentAuthorityId: new FormControl<number | string | null>({
        value: animalOrigin.competentAuthority?.id || null,
        disabled: !this.isEditingMode
      }, Validators.required),
      location: new FormControl<string | null>({
        value: animalOrigin.location,
        disabled: !this.isEditingMode
      }, Validators.required),
      reasonForTransfer: new FormControl({
        value: animalOrigin.reasonForGiving,
        disabled: !this.isEditingMode
      }, Validators.required),
      conditionOfCare: new FormControl<string | null>({
        value: animalOrigin.conditionOfCare,
        disabled: !this.isEditingMode
      }),
      dateOfReceipt: new FormControl<Date | null>({
        value: new Date(animalOrigin.dateOfReceipt),
        disabled: !this.isEditingMode
      }, Validators.required),
      nutritionalStatus: new FormControl<string | null>({
        value: animalOrigin.nutritionalStatus,
        disabled: !this.isEditingMode
      }),
      specialInformation: new FormControl<string | null>({
        value: animalOrigin.specialInformation,
        disabled: !this.isEditingMode
      }),
      originOtherComments: new FormControl<string | null>({
        value: animalOrigin.otherComments,
        disabled: !this.isEditingMode
      }),
      personSalutation: new FormControl<string>({
        value: animalOrigin.contact.gender || '',
        disabled: !this.isEditingMode
      }),
      personLastName: new FormControl<string>({
        value: animalOrigin.contact.lastName || '',
        disabled: !this.isEditingMode
      }),
      personFirstName: new FormControl<string>({
        value: animalOrigin.contact.firstName || '',
        disabled: !this.isEditingMode
      }),
      personCompanyName: new FormControl<string>({
        value: animalOrigin.contact.companyName || '',
        disabled: !this.isEditingMode
      }),
      personStreet: new FormControl<string>({
        value: animalOrigin.contact.street || '',
        disabled: !this.isEditingMode
      }),
      personCity: new FormControl<string>({
        value: animalOrigin.contact.city || '',
        disabled: !this.isEditingMode
      }),
      personZipCode: new FormControl<string>({
        value: animalOrigin.contact.zipCode || '',
        disabled: !this.isEditingMode
      }),
      personPhoneNumber: new FormControl<string>({
        value: animalOrigin.contact.phoneNumber || '',
        disabled: !this.isEditingMode
      }),
      personMobileNumber: new FormControl<string>({
        value: animalOrigin.contact.mobilePhoneNumber || '',
        disabled: !this.isEditingMode
      }),
      personEmail: new FormControl<string>({
        value: animalOrigin.contact.email || '',
        disabled: !this.isEditingMode
      }),
      personIdCardNumber: new FormControl<string>({
        value: animalOrigin.contact.idCardNumber || '',
        disabled: !this.isEditingMode
      }),
    }
  
    return this.formBuilder.group(formGroupConfig);
  }

  setReasonsForTransferOptions() {
    for (const reasonForTransfer in AnimalOriginReasonForTransfer) {
      this.originReasonForTransfer.push({
        name: this._translateService.translate(`animalAdd.origin.reasonForTransferSelect.${reasonForTransfer}`),
        value: reasonForTransfer
      });
    }
  }

  toggleEditDialog(originId: string) {
    this._animalEditService.toggleEditDialog({origin: originId});
  }

  enableControls() {
    Object.values(this.formGroupOrigins).forEach((form: FormGroup) => {
      Object.keys(form.controls).forEach(key => {
        form.controls[key].enable();
      });
    });
  }

  disableControls() {
    Object.values(this.formGroupOrigins).forEach((form: FormGroup) => {
      Object.keys(form.controls).forEach(key => {
        form.controls[key].disable();
      });
    });
  }

  wasGivenChanged($event: any, animalOriginId: string) {
    if ($event.checked === false) {
        if (this._helperService.checkAnyContactData(this.animalOrigItems[animalOriginId].contact)) {
            this.clearContactDialog = true;
            this.pendingOriginId = animalOriginId;
            this.tempOriginWasGiven[animalOriginId] = true;
            setTimeout(() => {
              this.cdr.detectChanges();
          }, 0);
        } else {
            this.originWasGiven[animalOriginId] = false;
            this.tempOriginWasGiven[animalOriginId] = false;
            this.cdr.detectChanges();
        }
    } else {
        this.originWasGiven[animalOriginId] = true;
        this.tempOriginWasGiven[animalOriginId] = true;
        if (this._helperService.checkAnyContactData(this.contacts[this.animalOrigItems[animalOriginId].contact?.id])) {
          const contact: ContactModel = this.contacts[this.animalOrigItems[animalOriginId].contact?.id];
          if (contact && this.formGroupOrigins[animalOriginId]) {
              this.formGroupOrigins[animalOriginId].patchValue({
                  personSalutation: contact.gender,
                  personFirstName: contact.firstName,
                  personLastName: contact.lastName,
                  personCompanyName: contact.companyName,
                  personStreet: contact.street,
                  personCity: contact.city,
                  personZipCode: contact.zipCode,
                  personPhoneNumber: contact.phoneNumber,
                  personMobileNumber: contact.mobilePhoneNumber,
                  personEmail: contact.email,
                  personIdCardNumber: contact.idCardNumber
              });
            }
        }
        this.cdr.detectChanges();
    }
}




removeContactDetails() {
  const controlsToReset = [
      'personSalutation',
      'personLastName',
      'personFirstName',
      'personCompanyName',
      'personStreet',
      'personCity',
      'personZipCode',
      'personPhoneNumber',
      'personMobileNumber',
      'personEmail',
      'personIdCardNumber'
  ];

  if (this.pendingOriginId) {
      controlsToReset.forEach(controlName => {
          const control = this.formGroupOrigins[this.pendingOriginId].get(controlName);
          if (control instanceof FormControl) {
              control.setValue('');
          }
      });

      this.originWasGiven[this.pendingOriginId] = false;
      this.tempOriginWasGiven[this.pendingOriginId] = false;
      this.clearContactDialog = false;
      this.pendingOriginId = '';
      this.cdr.detectChanges();
  }
}


onDialogCancel() {
  this.clearContactDialog = false;
  if (this.pendingOriginId) {
      this.tempOriginWasGiven[this.pendingOriginId] = !this.originWasGiven[this.pendingOriginId];
      setTimeout(() => {
          this.tempOriginWasGiven[this.pendingOriginId] = this.originWasGiven[this.pendingOriginId];
          this.pendingOriginId = '';
          this.cdr.detectChanges();
      }, 0);
  } else {
      this.cdr.detectChanges();
  }
}



  updateAnimalOrigin() {
    Object.entries(this.formGroupOrigins).forEach(([key, form]) => {
      if (form) {
        const originalDateOfReceipt = this._helperService.normalizeDate(new Date(this.animalOrigItems[key].dateOfReceipt));
        const newDateOfReceipt = this._helperService.normalizeDate(new Date(form.value.dateOfReceipt));

        let dateOfReceiptToSend;

        if (originalDateOfReceipt.getTime() === newDateOfReceipt.getTime()) {
            dateOfReceiptToSend = originalDateOfReceipt; // Verwende den ursprünglichen Wert
        } else {
            dateOfReceiptToSend = newDateOfReceipt; // Setze den neuen Wert, falls eine Änderung erfolgt ist
        }

        const contactPayload: { [key: string]: any } = {
          id: this.animalOrigItems[key].contact?.id,
          gender: form.value.personSalutation?.value,
          firstName: form.value.personFirstName,
          lastName: form.value.personLastName,
          companyName: form.value.personCompanyName,
          zipCode: form.value.personZipCode,
          city: form.value.personCity,
          street: form.value.personStreet,
          phoneNumber: form.value.personPhoneNumber,
          mobilePhoneNumber: form.value.personMobilePhoneNumber,
          email: form.value.personEmail,
          idCardNumber: form.value.personIdCardNumber
        }

        const payload = {
          animal: {
            id: this.animal?.id
          },
          competentAuthority: {
            id: form.value.competentAuthorityId
          },
          contact: contactPayload?.['id'] ? { id: contactPayload['id'] } : { id: '' },
          reasonForGiving: form.value.reasonForTransfer?.value,
          specialInformation: form.value.specialInformation,
          location: form.value.location,
          otherComments: form.value.originOtherComments,
          conditionOfCare: form.value.conditionOfCare,
          nutritionalStatus: form.value.nutritionalStatus,
          dateOfReceipt: dateOfReceiptToSend,
        };

        if (this.originWasGiven[key]) {
          if (!this.animalOrigItems[key].contact || (this.animalOrigItems[key].contact && !this.animalOrigItems[key].contact.id)) {
            this._contactRepository.create(contactPayload).pipe(take(1)).subscribe((response) => {
              const newContactId = response.id;
              if (newContactId) {
                payload.contact = {
                  "id": newContactId
                };
              }
              this._animalOriginRepository.update(key, payload).pipe(take(1)).subscribe(() => {});
            });
          } else {
            if (this.animalOrigItems[key] && this.animalOrigItems[key].id) {
              this._contactRepository.update(
                contactPayload,
                contactPayload['id']
              ).pipe(take(1)).subscribe(() => {
                this._animalOriginRepository.update(key, payload).pipe(take(1)).subscribe(() => {
                });
              });
            }
          }
        } else {
          Object.keys(contactPayload).forEach(key => {
            if (key !== 'id') {
              contactPayload[key] = '';
            }
          });
          this._contactRepository.create(contactPayload).pipe(take(1)).subscribe((response) => {
            this._animalOriginRepository.update(key, payload).pipe(take(1)).subscribe(() => {});
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}