import { HelperService } from 'src/app/shared/services/helper.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {AnimalFilter} from "../animal-filters/animal-filter.model";
import {TranslocoService} from "@ngneat/transloco";
import {AnimalCategory} from "../models/animal-category.enum";
import {AnimalType} from "../models/animal-type.enum";
import {AnimalCharacteristicsModel} from "../models/animal-characteristics.model";
import {AnimalCharacteristics, getCharacteristicsEnumKey} from "../models/animal-characteristics.enum";
import {AnimalCoatType} from "../models/animal-coat-type.enum";
import {AnimalHint} from "../models/animal-hint.enum";
import {AnimalTypeModel} from "../models/animal-type.model";
import {AnimalCategoryModel} from "../models/animal-category.model";
import {AnimalCoatTypeModel} from "../models/animal-coat-type.model";
import {AnimalHintModel} from "../models/animal-hint.model";

@Injectable({
  providedIn: 'root'
})
export class AnimalService {
  private selectedFiltersSubject: BehaviorSubject<AnimalFilter[]> = new BehaviorSubject<AnimalFilter[]>([]);
  selectedFilters$: Observable<AnimalFilter[]> = this.selectedFiltersSubject.asObservable();
  selectedFilters: AnimalFilter[] = [];

  constructor(
    private _translateService: TranslocoService,
    private _helperService: HelperService
  ) {
  }

  addFilter(filter: AnimalFilter): void {
    if (!this.selectedFilters.find(f => f.id === filter.id)) {
      this.selectedFilters.push(filter);
      this.selectedFiltersSubject.next(this.selectedFilters);
    }
  }

  removeFilter(filter: AnimalFilter): void {
    const index = this.selectedFilters.findIndex(f => f.id === filter.id);
    if (index > -1) {
      this.selectedFilters.splice(index, 1);
      this.selectedFiltersSubject.next(this.selectedFilters);
    }
  }

  getFilterCategories() {
    const categories: AnimalFilter[] = [];

    Object.entries(AnimalCategory).forEach(([key]) => {
      categories.push({
        'id': key,
        'imgSrc': `./assets/images/animals/icon_${key.toLowerCase()}.svg`,
        'title': '',
      });
      this._translateService.selectTranslate(`animalOverview.filters.${key}`).subscribe((translation: string) => {
        const category = categories.find(category => category.id === key);
        if (category) {
          category.title = translation;
        }
      });
    });

    return categories;
  }

  getAnimalFilterByCategory(category: string): AnimalFilter | undefined {
    return this.getFilterCategories().find(filter => filter.id.toLowerCase() === category.toLowerCase());
  }

  getGenderOptions() {
    return [{
      name: this._translateService.translate('male'),
      key: 'male'
    }, {
      name: this._translateService.translate('female'),
      key: 'female'
    }];
  }

  getAnimalsCategoryKeyOfValue(value: AnimalCategory): keyof typeof AnimalCategory | null {
    const keys = Object.keys(AnimalCategory) as (keyof typeof AnimalCategory)[];

    for (const key of keys) {
      if (AnimalCategory[key] === value) {
        return key;
      }
    }

    return null;
  }


  getAnimalTypes(): AnimalTypeModel[] {
    return this._helperService.getEnumOptions(AnimalType, 'animal.type');
  }

  getAnimalCategories(): AnimalCategoryModel[] {
    return this._helperService.getEnumOptions(AnimalCategory, 'animal.category');
  }

  getCoatTypes(): AnimalCoatTypeModel[] {
    return this._helperService.getEnumOptions(AnimalCoatType, 'animal.coatType');
  }

  getHints(): AnimalHintModel[] {
    return this._helperService.getEnumOptions(AnimalHint, 'animal.hint');
  }

  getCharacteristics(): AnimalCharacteristicsModel[] {
    return this._helperService.getEnumOptions(AnimalCharacteristics, 'animal.characteristics');
  }

  getCharacteristicsForCategory(filter: AnimalFilter) {
    let characteristics: AnimalCharacteristics[] = [
      AnimalCharacteristics.FOSTER,
      AnimalCharacteristics.SENIOR,
      AnimalCharacteristics.LONER,
      AnimalCharacteristics.CAN_DRIVE_CAR,
      AnimalCharacteristics.CAN_OPEN_DOORS,
      AnimalCharacteristics.SUITABLE_FOR_BEGINNERS,
      AnimalCharacteristics.SUITABLE_FOR_FAMILIES,
      AnimalCharacteristics.NEEDS_MEDICATION,
      AnimalCharacteristics.NEEDS_ADJUSTMENT_PERIOD,
      AnimalCharacteristics.NEEDS_TIME_TO_BUILD_TRUST,
      AnimalCharacteristics.SENSITIVE,
      AnimalCharacteristics.SHY,
      AnimalCharacteristics.DOESNT_LIKE_TO_BE_TOUCHED,
      AnimalCharacteristics.DOESNT_LIKE_TO_BE_PICKED_UP,
      AnimalCharacteristics.CURIOUS,
      AnimalCharacteristics.HAND_TAMED,
      AnimalCharacteristics.GOOD_SOCIAL_BEHAVIOR,
      AnimalCharacteristics.INSECURE,
      AnimalCharacteristics.ANXIOUS,
      AnimalCharacteristics.ENERGETIC,
      AnimalCharacteristics.HUMAN_ORIENTED,
      AnimalCharacteristics.MIXED_GROUP,
      AnimalCharacteristics.HAS_ILLNESS_STOMACH_ULCER,
      AnimalCharacteristics.LEASH_TRAINED,
      AnimalCharacteristics.COMPATIBLE_WITH_SAME_SPECIES,
      AnimalCharacteristics.COMPATIBLE_WITH_CHILDREN,
      AnimalCharacteristics.NOT_COMPATIBLE_WITH_SAME_SPECIES,
      AnimalCharacteristics.NOT_COMPATIBLE_WITH_MEN,
      AnimalCharacteristics.NOT_COMPATIBLE_WITH_WOMEN,
      AnimalCharacteristics.NOT_COMPATIBLE_WITH_DOGS
    ];

    switch (filter.id.toLowerCase()) {
      case AnimalCategory.CAT:
        characteristics = characteristics.concat([
          AnimalCharacteristics.INDOOR_CAT,
          AnimalCharacteristics.OUTDOOR_CAT,
          AnimalCharacteristics.LIKES_DOGS,
          AnimalCharacteristics.GOOD_MOUSER,
          AnimalCharacteristics.INDEPENDENT,
          AnimalCharacteristics.VOCAL,
          AnimalCharacteristics.LITTER_TRAINED,
          AnimalCharacteristics.BINDS_TO_ONE_PERSON,
          AnimalCharacteristics.NOT_COMPATIBLE_WITH_BIRDS,
          AnimalCharacteristics.NOT_COMPATIBLE_WITH_SMALL_ANIMALS
        ]);
        break;
      case AnimalCategory.DOG:
        characteristics = characteristics.concat([
          AnimalCharacteristics.CAN_STAY_ALONE,
          AnimalCharacteristics.LIKES_CAT,
          AnimalCharacteristics.CUDDLER,
          AnimalCharacteristics.ONLY_FOR_PEOPLE_WITH_DOG_EXPERIENCE,
          AnimalCharacteristics.ONLY_FOR_PEOPLE_WITH_BREED_EXPERIENCE,
          AnimalCharacteristics.RACE_RELATED_HUNTING_INSTINCT,
          AnimalCharacteristics.GOOD_WATCHDOG,
          AnimalCharacteristics.REQUIRES_FIRM_TRAINING,
          AnimalCharacteristics.QUIET,
          AnimalCharacteristics.OBEDIENT,
          AnimalCharacteristics.QUIET,
          AnimalCharacteristics.AFFECTIONATE,
          AnimalCharacteristics.DEMANDING,
          AnimalCharacteristics.PLAYFUL,
          AnimalCharacteristics.JEALOUS,
          AnimalCharacteristics.UNCOMPLICATED,
          AnimalCharacteristics.STUBBORN,
          AnimalCharacteristics.BALANCED,
          AnimalCharacteristics.SUBMISSIVE,
          AnimalCharacteristics.DOMINANT,
          AnimalCharacteristics.LOVES_COMMOTION,
          AnimalCharacteristics.ATTENTIVE,
          AnimalCharacteristics.NEEDS_EXERCISE,
          AnimalCharacteristics.DOESNT_LIKE_BEING_ALONE,
          AnimalCharacteristics.NEEDS_LOTS_OF_ACTIVITY,
          AnimalCharacteristics.LOVES_SWIMMING,
          AnimalCharacteristics.WELL_TRAINED,
          AnimalCharacteristics.KNOWS_BASIC_COMMANDS,
          AnimalCharacteristics.NEEDS_TRAINING,
          AnimalCharacteristics.NEEDS_LOTS_OF_AFFECTION,
          AnimalCharacteristics.PROTECTIVE_INSTINCT,
          AnimalCharacteristics.HERDING_INSTINCT,
          AnimalCharacteristics.LIKES_TO_HUNT,
          AnimalCharacteristics.CLIMBS_OVER_FENCES,
          AnimalCharacteristics.BARKER,
          AnimalCharacteristics.BINDS_TO_ONE_PERSON,
          AnimalCharacteristics.LITTER_TRAINED,
          AnimalCharacteristics.COMPATIBLE_WITH_SMALL_ANIMALS,
          AnimalCharacteristics.NOT_COMPATIBLE_WITH_LARGE_DOGS,
          AnimalCharacteristics.NOT_COMPATIBLE_WITH_SMALL_DOGS,
          AnimalCharacteristics.NOT_COMPATIBLE_WITH_BIRDS,
          AnimalCharacteristics.NOT_COMPATIBLE_WITH_SMALL_ANIMALS,
          AnimalCharacteristics.NOT_COMPATIBLE_WITH_CATS,
        ]);
        break;
      case AnimalCategory.HORSE:
        characteristics = characteristics.concat([
          AnimalCharacteristics.OPEN_STABLE_KEEPING,
          AnimalCharacteristics.STABLE_KEEPING_WITH_FREEDOM,
          AnimalCharacteristics.ASSISTENT_HORSE,
          AnimalCharacteristics.EQUINE_PASSPORT,
          AnimalCharacteristics.RIDING_HORSE,
          AnimalCharacteristics.EASY_TO_LOAD,
          AnimalCharacteristics.DRESSAGE_HORSE,
          AnimalCharacteristics.JUMPER,
          AnimalCharacteristics.NEEDS_EXPERIENCED_HANDLER,
          AnimalCharacteristics.MUST_BE_FED_ADDITIONALLY,
          AnimalCharacteristics.EASY_KEEPER,
          AnimalCharacteristics.HARD_KEEPER,
          AnimalCharacteristics.TRAILER_FRIENDLY,
          AnimalCharacteristics.UNBROKEN,
          AnimalCharacteristics.LONGED,
          AnimalCharacteristics.SADDLE_BROKEN,
          AnimalCharacteristics.FULLY_TRAINED,
          AnimalCharacteristics.DRIVING_HORSE,
          AnimalCharacteristics.EASY_MEDICATION_ADMINISTRATION,
          AnimalCharacteristics.GOOD_WITH_VETERINARIAN,
          AnimalCharacteristics.NERVOUS,
          AnimalCharacteristics.STRESS_PRONE,
          AnimalCharacteristics.LEAD_MARE,
          AnimalCharacteristics.SECURE_FENCING,
          AnimalCharacteristics.FAMILIAR_WITH_MACHINERY_VEHICLES_ANIMALS,
          AnimalCharacteristics.GELDING_HERD_MIXED_HERD,
          AnimalCharacteristics.TRAINED_FOR_HORSEMAN_SHIP,
          AnimalCharacteristics.HAS_ILLNESS_CUSHING,
          AnimalCharacteristics.HAS_ILLNESS_COLIC,
          AnimalCharacteristics.HAS_ILLNESS_LAMINITIS,
          AnimalCharacteristics.SUBMISSIVE,
          AnimalCharacteristics.DOMINANT,
          AnimalCharacteristics.HIGH_RANKING,
          AnimalCharacteristics.LOW_RANKING,
          AnimalCharacteristics.COMPATIBLE_WITH_DOGS,
          AnimalCharacteristics.COMPATIBLE_WITH_FARRIER,
        ]);
        break;
      case AnimalCategory.SMALL_ANIMAL:
        characteristics = characteristics.concat([
          AnimalCharacteristics.GENTLE,
          AnimalCharacteristics.LIVELY,
          AnimalCharacteristics.REQUIRES_GROOMING,
          AnimalCharacteristics.ENCLOSURE_NEEDS_MINIMUM_HEIGHT,
          AnimalCharacteristics.ENCLOSURE_NEEDS_PREDATOR_PROTECTION,
          AnimalCharacteristics.ENCLOSURE_NEEDS_DIGGING_PROTECTION,
          AnimalCharacteristics.ENCLOSURE_NEEDS_MINIMUM_SIZE
        ]);
        break;
    }

    const returnCharacteristics: AnimalCharacteristicsModel[] = [];
    characteristics.forEach((characteristic) => {
      returnCharacteristics.push({
        name: this._translateService.translate('animal.characteristics.' + getCharacteristicsEnumKey(characteristic)),
        value: characteristic
      });
    });

    return returnCharacteristics;
  }
}