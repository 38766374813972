import { AnimalImageModel } from './../models/animal-image.model';
import { Component, Input } from '@angular/core';
import { RestService } from 'src/app/shared/services/rest.service';
import { combineLatest, take } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { AnimalModel } from '../models/animal.model';
import { AnimalImagesRepository } from '../states/animal-images.repository';
import { WaitingDialogService } from 'src/app/shared/services/waiting-dialog.service';

@Component({
  selector: 'app-animal-details-timeline',
  templateUrl: './animal-details-timeline.component.html',
  styleUrls: ['./animal-details-timeline.component.scss']
})
export class AnimalDetailsTimelineComponent {
  @Input() animal: AnimalModel | undefined;
  events: any[] = [];
  animalImages: AnimalImageModel[] = [];

  constructor(
    private _restService: RestService,
    private _translocoService: TranslocoService,
    private _animalImagesRepository: AnimalImagesRepository,
    private _waitingDialogService: WaitingDialogService
  ) { }

  loadData() {
    if (this.animal) {
      this._waitingDialogService.showWaitingDialog(true);
      combineLatest([
        this._restService.httpGet('/animals/' + this.animal?.id + '/history'),
        this._animalImagesRepository.getImages(this.animal?.id)
      ]).pipe(take(1)).subscribe(([history, images]) => {
        this.animalImages = images;
        this.processServerData(history);
        this._waitingDialogService.showWaitingDialog(false);
      });
    }
  }

  actionMap: {
    [key: string]: {
      [key: string]: (data: any) => string
    }
  } = {
      'create': {
        'App\\Entity\\Animal\\Animal': (data) => this._translocoService.translate('animal_created', { name: data.name }),
        'App\\Entity\\Animal\\AnimalImage': (data) => data.preview ? this._translocoService.translate('image_set_as_preview') : this._translocoService.translate('image_added'),
        'App\\Entity\\Animal\\AnimalWhereabouts': (data) => this._translocoService.translate('whereabouts_created', { date: data.date })
      },
      'update': {
        'App\\Entity\\Animal\\Animal': () => this._translocoService.translate('animal_property_changed'),
        'App\\Entity\\Animal\\AnimalImage': (data) => data.preview ? this._translocoService.translate('image_set_as_preview') : this._translocoService.translate('image_added'),
        'App\\Entity\\Animal\\AnimalOrigin': () => this._translocoService.translate('origin_property_changed'),
        'App\\Entity\\Animal\\AnimalWhereabouts': () => this._translocoService.translate('whereabouts_property_changed'),
      }
    };

  dataMap: {
    [key: string]: (data: any) => string
  } = {
      'App\\Entity\\Animal\\Animal': (data) => this.transformAnimalData(data),
      'App\\Entity\\Animal\\AnimalImage': () => '',
      'App\\Entity\\Animal\\AnimalOrigin': (data) => this.transformAnimalOriginData(data),
      'App\\Entity\\Animal\\AnimalWhereabouts': (data) => this.transformAnimalWhereaboutsData(data)
    };

  transformAnimalData(data: any) {
    const properties = [
      'category', 'type', 'bookNumber', 'breed', 'oldBookNumber', 'petPassportNumber', 'lifeNumber', 'transponderNumber',
      'sex', 'coatType', 'coatColor', 'size', 'receiptDate', 'name', 'weight', 'birthday', 'birthYear', 
      'specialInformation', 'castratedDate', 'sterilizedDate', 'tattoo', 'tattooLocation', 'ringNumber', 'earTag', 
      'hints', 'internalDetails', 'externalDetails', 'externalShortDescription', 'externalDescription', 
      'syncWithWebsite', 'nameNew', 'nameOld', 'websiteMetaDescription', 'websiteMetaTitle', 'sanctuary'
    ];

    return this.transformData(data, properties, 'timelineAnimal');
  }

  transformAnimalOriginData(data: any) {
    const properties = [
      'dateOfReceipt', 'reasonForGiving', 'specialInformation', 'location', 'otherComments', 'conditionOfCare', 
      'nutritionalStatus', 'unknownSubmittedPerson', 'submittedPersonsSalutation', 'submittedPersonsLastName', 
      'submittedPersonsFirstName', 'submittedPersonsZipCode', 'submittedPersonsCity', 'submittedPersonsAddress', 
      'submittedPersonsPhoneNumber', 'submittedPersonsMobilePhoneNumber', 'submittedPersonsMailAddress'
    ];

    return this.transformData(data, properties, 'timelineOrigin');
  }

  transformAnimalWhereaboutsData(data: any) {
    const properties = [
      'date', 'dateCare', 'information', 'deletedAt'
    ];

    return this.transformData(data, properties, 'timelineWhereabout');
}

transformData(data: any, properties: string[], translationPrefix: string): string {
  let result = '';

  properties.forEach(prop => {
      if (data.hasOwnProperty(prop) && data[prop] !== null && data[prop] !== undefined) {
          // Nur Properties verarbeiten, die im Data-Objekt vorhanden und nicht null sind
          const translatedProp = this._translocoService.translate(`${translationPrefix}.${prop}`);
          let value = data[prop];
          if (this.isDate(prop, value)) {
            const date = new Date(value);
            value = this.formatDate(date, prop);
          } else if (prop === 'reasonForGiving') {
            value = this._translocoService.translate(`animalAdd.origin.reasonForTransferSelect.${value}`);
          } else if (prop === 'sex') {
            value = this._translocoService.translate(`${value}`);
          }
          result += `${translatedProp}: ${value}<br>`;
      }
  });

  return result;
}
  

  formatDate(date: Date, prop: string): string {
    if (prop === 'birthYear') {
      return date.getFullYear().toString();
    } else {
      return date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
    }
  }

  isDate(prop: string, value: string): boolean {
    const dateProps = ['date', 'dateOfReceipt', 'birthYear', 'birthday']; // Add any other date properties here
    return dateProps.includes(prop) && !isNaN(Date.parse(value));
  }

  processServerData(serverData: any[]) {
    this.events = serverData?.reduce((events, item) => {
      if (this.shouldProcessItem(item)) {
        const event = this.createEvent(item);
        events.push(event);
      }
      return events;
    }, []);
  }

  shouldProcessItem(item: any): boolean {
    return !!this.actionMap[item.action] && !!this.actionMap[item.action][item.objectClass];
  }
  

  createEvent(item: any) {
    const status = this.getStatus(item);
    const event = {
      status: status,
      date: new Date(item.loggedAt).toLocaleString('de-DE'),
      image: this.getImage(item),
      imageId: item.objectClass.endsWith('AnimalImage') ? item.objectId : '',
      name: '',
      data: this.getData(item),
      icon: this.getIcon(item),
      backgroundColor: this.getBackgroundColor(item)
    };
    return event;
  }
  
  getData(item: any): string {
    // Überprüfen, ob die dataMap für diese Objektklasse definiert ist
    if (this.dataMap[item.objectClass]) {
        return this.dataMap[item.objectClass](item.data);
    }
    return 'No data available';  // Um klar zu machen, wenn keine Daten verarbeitet werden
}
  

  getStatus(item: any): string {
    return this.actionMap[item.action][item.objectClass](item.data);
  }

  getImage(item: any): AnimalImageModel | undefined {
    return item.objectClass.endsWith('AnimalImage') ? this.animalImages.find(image => image.id.toString() === item.objectId) : undefined;
  }

  getIcon(item: any): string {
    if (item.action === 'create') {
      return 'pi pi-plus';
    } else if ((item.objectClass.endsWith('Animal') || item.objectClass.endsWith('AnimalOrigin')) && item.action === 'update') {
      return 'pi pi-pencil';
    } else if (item.objectClass.endsWith('AnimalImage')) {
      return 'pi pi-image';
    }
    return '';
  }

  getBackgroundColor(item: any): string {
    if (item.action === 'create') {
      return 'var(--green-600)';
    } else if ((item.objectClass.endsWith('Animal') || item.objectClass.endsWith('AnimalOrigin')) && item.action === 'update') {
      return 'var(--yellow-500)';
    } else if (item.objectClass.endsWith('AnimalImage')) {
      return 'var(--primary-500)';
    }
    return '';
  }


  dummyTranslateFunction() {
    // Aufruf der Übersetzungsschlüssel für AnimalModel-Entitäten
    this._translocoService.translate('timelineAnimal.id');
    this._translocoService.translate('timelineAnimal.createdDate');
    this._translocoService.translate('timelineAnimal.updatedAt');
    this._translocoService.translate('timelineAnimal.animalShelter');
    this._translocoService.translate('timelineAnimal.images');
    this._translocoService.translate('timelineAnimal.origins');
    this._translocoService.translate('timelineAnimal.whereabouts');
    this._translocoService.translate('timelineAnimal.category');
    this._translocoService.translate('timelineAnimal.type');
    this._translocoService.translate('timelineAnimal.breed');
    this._translocoService.translate('timelineAnimal.bookNumber');
    this._translocoService.translate('timelineAnimal.oldBookNumber');
    this._translocoService.translate('timelineAnimal.petPassportNumber');
    this._translocoService.translate('timelineAnimal.equinePassportNumber');
    this._translocoService.translate('timelineAnimal.lifeNumber');
    this._translocoService.translate('timelineAnimal.transponderNumber');
    this._translocoService.translate('timelineAnimal.sex');
    this._translocoService.translate('timelineAnimal.coatType');
    this._translocoService.translate('timelineAnimal.coatColor');
    this._translocoService.translate('timelineAnimal.size');
    this._translocoService.translate('timelineAnimal.receiptDate');
    this._translocoService.translate('timelineAnimal.name');
    this._translocoService.translate('timelineAnimal.nameOld');
    this._translocoService.translate('timelineAnimal.nameNew');
    this._translocoService.translate('timelineAnimal.displayName');
    this._translocoService.translate('timelineAnimal.weight');
    this._translocoService.translate('timelineAnimal.birthday');
    this._translocoService.translate('timelineAnimal.birthYear');
    this._translocoService.translate('timelineAnimal.specialInformation');
    this._translocoService.translate('timelineAnimal.castratedDate');
    this._translocoService.translate('timelineAnimal.castrated');
    this._translocoService.translate('timelineAnimal.castratedNote');
    this._translocoService.translate('timelineAnimal.sterilized');
    this._translocoService.translate('timelineAnimal.sterilizedDate');
    this._translocoService.translate('timelineAnimal.tattoo');
    this._translocoService.translate('timelineAnimal.tattooLocation');
    this._translocoService.translate('timelineAnimal.ringNumber');
    this._translocoService.translate('timelineAnimal.earTag');
    this._translocoService.translate('timelineAnimal.previewImage');
    this._translocoService.translate('timelineAnimal.hints');
    this._translocoService.translate('timelineAnimal.internalDetails');
    this._translocoService.translate('timelineAnimal.externalDetails');
    this._translocoService.translate('timelineAnimal.externalShortDescription');
    this._translocoService.translate('timelineAnimal.externalDescription');
    this._translocoService.translate('timelineAnimal.syncWithWebsite');
    this._translocoService.translate('timelineAnimal.websiteMetaDescription');
    this._translocoService.translate('timelineAnimal.websiteMetaTitle');
    this._translocoService.translate('timelineAnimal.sanctuary');
  
    // Aufruf der Übersetzungsschlüssel für AnimalWhereaboutModel-Entitäten
    this._translocoService.translate('timelineWhereabout.id');
    this._translocoService.translate('timelineWhereabout.animal');
    this._translocoService.translate('timelineWhereabout.contact');
    this._translocoService.translate('timelineWhereabout.type');
    this._translocoService.translate('timelineWhereabout.date');
    this._translocoService.translate('timelineWhereabout.dateCare');
    this._translocoService.translate('timelineWhereabout.information');
  
    // Aufruf der Übersetzungsschlüssel für AnimalOriginModel-Entitäten
    this._translocoService.translate('timelineOrigin.id');
    this._translocoService.translate('timelineOrigin.animal');
    this._translocoService.translate('timelineOrigin.competentAuthority');
    this._translocoService.translate('timelineOrigin.contact');
    this._translocoService.translate('timelineOrigin.dateOfReceipt');
    this._translocoService.translate('timelineOrigin.reasonForGiving');
    this._translocoService.translate('timelineOrigin.specialInformation');
    this._translocoService.translate('timelineOrigin.location');
    this._translocoService.translate('timelineOrigin.otherComments');
    this._translocoService.translate('timelineOrigin.conditionOfCare');
    this._translocoService.translate('timelineOrigin.nutritionalStatus');
  }
}
