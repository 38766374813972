<div class="atd-bg bg-auto bg-no-repeat bg-center min-h-screen w-full">
    <div class="bg-white login-container border-round">
        <div class="flex flex-column">
            <div class="flex flex-row justify-content-center">
                <img class="logo" src="./../../../assets/layout/images/logo_small.svg" alt="Login">
            </div>
            <div class="flex flex-row justify-content-center align-items-center mt-2">
                <p class="font-bold my-4 headline">{{ 'login.headline' | transloco }}</p>
            </div>
            <div class="flex flex-row justify-content-center align-items-center mt-2 mb-4">
                <input [attr.type]="showPassword ? 'text' : 'password'"
                       class="p-2 border-1 border-gray-200 w-12 pin-input"
                       [(ngModel)]="pin" (input)="pinChanged()"
                       autofocus
                >
                <span (click)="onShowPswClear()" *ngIf="showPassword" class="icon-clear pi pi-eye"></span>
                <span (click)="onShowPswClear()" *ngIf="!showPassword" class="icon-clear pi pi-eye-slash"></span>
            </div>
            <div class="flex flex-row justify-content-center align-items-center">
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(1)">
                    <span>1</span>
                </div>
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(2)">
                    <span>2</span>
                </div>
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(3)">
                    <span>3</span>
                </div>
            </div>
            <div class="flex flex-row justify-content-center align-items-center">
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(4)">
                    <span>4</span>
                </div>
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(5)">
                    <span>5</span>
                </div>
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(6)">
                    <span>6</span>
                </div>
            </div>
            <div class="flex flex-row justify-content-center align-items-center">
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(7)">
                    <span>7</span>
                </div>
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(8)">
                    <span>8</span>
                </div>
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(9)">
                    <span>9</span>
                </div>
            </div>
            <div class="flex flex-row justify-content-center align-items-center">
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem border-circle select-none pin-number">
                    <!-- space for styling -->
                    <span></span>
                </div>
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress(0)">
                    <span>0</span>
                </div>
                <div class="flex align-items-center justify-content-center m-2 w-3rem h-3rem bg-primary border-circle cursor-pointer select-none pin-number"
                     (click)="fieldPress('clear')">
                    <span><</span>
                </div>
            </div>
        </div>
    </div>
</div>
