import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AnimalRepository } from "../states/animal.repository";
import { ActivatedRoute } from "@angular/router";
import { Observable, takeWhile } from "rxjs";
import { AnimalEditService } from "../services/animal-edit.service";
import { AnimalModel } from "../models/animal.model";
import { CompetentAuthoritiesService } from 'src/app/shared/services/competent-authorities.service';
import { GalleriaResponsiveOptions } from 'primeng/galleria';
import { AnimalImagesRepository } from '../states/animal-images.repository';
import {getAnimalHintKey} from "../models/animal-hint.enum";
import {getCharacteristicsEnumKey} from "../models/animal-characteristics.enum";
import { AnimalDetailsTimelineComponent } from '../animal-details-timeline/animal-details-timeline.component';

@Component({
  selector: 'app-animal-details',
  templateUrl: './animal-details.component.html',
  styleUrls: ['./animal-details.component.scss']
})
export class AnimalDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('animalDetailsTimelineComponent') animalDetailsTimelineComponent!: AnimalDetailsTimelineComponent;
  alive: boolean;
  animal$: Observable<AnimalModel | undefined> = new Observable<AnimalModel | undefined>();
  responsiveOptions: GalleriaResponsiveOptions[] = [];
  editTabsDialogVisible: { [tab: string]: boolean  | string} = {};
  showEditDialog: boolean = false;
  filteredCoatTypes: string[] | undefined = [];

  constructor(
    private _animalRepository: AnimalRepository,
    private _route: ActivatedRoute,
    private _animalImagesRepository: AnimalImagesRepository,
    private _animalEditService: AnimalEditService,
  ) {
    this.alive = true;
  }

  ngOnInit(): void {

    this._animalEditService.editDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.editTabsDialogVisible = openModal;
      this.showEditDialog = openModal && Object.keys(openModal).length ? true : false;
    });

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];

    const idStr = this._route.snapshot.paramMap.get('id');
    if (idStr !== undefined && idStr !== null) {
      const id = parseInt(idStr);
      this.animal$ = this._animalRepository.getById(id);
      this.animal$.pipe(takeWhile(() => this.alive)).subscribe((animal) => {
        this.filteredCoatTypes = animal?.coatType.filter(x => x !== null && x !== '');
      });
    }
  }

  toggleEditDialog() {
    this._animalEditService.toggleEditDialog({all: true});
  }

  onTabOpenChange(event: any) {
    if (event.index === 3) {
      this.animalDetailsTimelineComponent.loadData();
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.showEditDialog = false;
  }

  protected readonly getAnimalHintKey = getAnimalHintKey;
  protected readonly getCharacteristicsEnumKey = getCharacteristicsEnumKey;
}