import { Injectable } from '@angular/core';
import { AnimalSheltersRepository } from 'src/app/modules/animals/states/animal-shelter.repository';
import { AnimalRepository } from 'src/app/modules/animals/states/animal.repository';
import { take } from 'rxjs';
import { AuthService } from './auth.service';
import { CompetentAuthoritiesRepository } from '../states/competent-authorities.repository';
import { ContactRepository } from 'src/app/modules/contact/states/contact.repository';


@Injectable({
  providedIn: 'root'
})
export class AppDataLoadService {

  constructor(
    private authService: AuthService,
    private _animalRepository: AnimalRepository,
    private _animalShelterRepository: AnimalSheltersRepository,
    private _competentAuthoritiesRepository: CompetentAuthoritiesRepository,
    private _contactRepository: ContactRepository
  ) { }

  async loadAppData() {
    const user = await this.authService.currentUser$.pipe(take(1)).toPromise();
    this._animalShelterRepository.loadAnimalShelters();
    this._competentAuthoritiesRepository.loadCompetentAuthorities();
    this._contactRepository.fetchData();
    if (user && user.defaultAnimalShelter !== null) {
      this._animalRepository.getByShelter(user.defaultAnimalShelter.id);
    } else {
      this._animalRepository.getAll();
    }
  }
}
