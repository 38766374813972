<div class="card" *ngIf="animal$ | async as animal">
  <div class="flex align-items-center mb-3">
    <div class="flex">
      <i class="pi pi-angle-double-left cursor-pointer" style="color:#7e9c30;font-size: 2rem"
         routerLink="/tiere"></i>
    </div>
    <div class="px-4">
      <app-animal-current-category [animal]="animal"></app-animal-current-category>
    </div>
    <div class="flex w-5rem h-5rem relative">
      <app-animal-image-container [animal]="animal" [preview]="true" [iconBadge]="false"></app-animal-image-container>
    </div>
    <div class="flex px-4 animal-name-headline">
      {{ animal?.displayName }}
    </div>
    <div class="flex px-4 animal-key">
      <img src="./assets/images/icon_key_green.svg" alt="key">
      <p>{{ animal.bookNumber }} <span *ngIf="animal.oldBookNumber">({{animal.oldBookNumber}})</span></p>
    </div>
    <div class="flex-1 flex justify-content-end">
      <p-button class="p-button-rounded p-button-secondary" icon="pi pi-pencil"
                label="Tier bearbeiten" (click)="toggleEditDialog()"></p-button>
    </div>
  </div>
  <p-tabView [activeIndex]="0" (onChange)="onTabOpenChange($event)">
    <p-tabPanel header="{{ 'animalDetails.general.headline' | transloco }}">
      <div class="card details-view">
        <div class="grid">
          <div class="col-6">
            <p-accordion [multiple]="true" [activeIndex]="[0,1]">
              <p-accordionTab header="{{ 'animalDetails.general.basicData.headline' | transloco}}">
                <div class="grid">
                  <div class="col-6" *ngIf="animal.nameOld">
                    <small>{{ 'animalDetails.general.basicData.nameOld' | transloco }}</small>
                    <p>{{ animal.nameOld }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.nameNew">
                    <small>{{ 'animalDetails.general.basicData.nameNew' | transloco }}</small>
                    <p>{{ animal.nameNew }}</p>
                  </div>
                  <div class="col-6">
                    <small>{{ 'animalDetails.general.basicData.category' | transloco }}</small>
                    <p>{{ 'animal.category.' + animal.category?.toUpperCase() | transloco }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.sex">
                    <small>{{ 'animalDetails.general.basicData.sex' | transloco }}</small>
                    <p>
                      <app-animal-sex-icon [animal]="animal"></app-animal-sex-icon>
                    </p>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab header="{{ 'animalDetails.general.specifications.headline' | transloco}}">
                <div class="grid">
                  <div class="col-6" *ngIf="animal.birthYear || animal.birthday">
                    <small>{{ 'animalDetails.general.specifications.birthday' | transloco }}</small>
                    <p *ngIf="animal.birthYear">{{ animal.birthYear | dateYear: 'yyyy' }}</p>
                    <p *ngIf="animal.birthday">{{ animal.birthday | date:'dd.MM.yyyy' }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.breed">
                    <small>{{ 'animalDetails.general.specifications.breed' | transloco }}</small>
                    <p>{{ animal.breed }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.coatType.length">
                    <small>{{ 'animalDetails.general.specifications.coatType' | transloco }}</small>
                    <p>
                      <span *ngFor="let coatType of filteredCoatTypes; let isLast=last">
                        {{ 'animal.coatType.' + coatType.toUpperCase() | transloco }}
                        <ng-container *ngIf="!isLast">,</ng-container>
                      </span>
                    </p>
                  </div>
                  <div class="col-6" *ngIf="animal.coatColor">
                    <small>{{ 'animalDetails.general.specifications.coatColor' | transloco }}</small>
                    <p>{{ animal.coatColor }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.size">
                    <small>{{ 'animalDetails.general.specifications.size' | transloco }}</small>
                    <p>{{ (animal.size !== null ? animal.size / 10 : '') }} <span *ngIf="animal.size !== null">cm</span>
                    </p>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
          <div class="col-6">
            <p-accordion [multiple]="true" [activeIndex]="[0, 1]">
              <p-accordionTab header="{{ 'animalDetails.general.numbers.headline' | transloco}}">
                <div class="grid">
                  <div class="col-6" *ngIf="animal.transponderNumber">
                    <small>{{ 'animalDetails.general.numbers.transponderNumber' | transloco }}</small>
                    <p>{{ animal.transponderNumber }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.lifeNumber">
                    <small>{{ 'animalDetails.general.numbers.lifeNumber' | transloco }}</small>
                    <p>{{ animal.lifeNumber }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.petPassportNumber">
                    <small>{{ 'animalAdd.general.numbers.petPassportNumber' | transloco }}</small>
                    <p>{{ animal.petPassportNumber }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.equinePassportNumber">
                    <small>{{ 'animalAdd.general.numbers.equinePassportNumber' | transloco }}</small>
                    <p>{{ animal.equinePassportNumber }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.ringNumber">
                    <small>{{ 'animalDetails.general.numbers.ringNumber' | transloco }}</small>
                    <p>{{ animal.ringNumber }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.earTag">
                    <small>{{ 'animalDetails.general.numbers.earTagNumber' | transloco }}</small>
                    <p>{{ animal.earTag }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.tattoo">
                    <small>{{ 'animalDetails.general.numbers.tattoo' | transloco }}</small>
                    <p>{{ animal.tattoo }}</p>
                  </div>
                  <div class="col-6" *ngIf="animal.tattooLocation">
                    <small>{{ 'animalDetails.general.numbers.tattooLocation' | transloco }}</small>
                    <p>{{ animal.tattooLocation }}</p>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab
                  header="{{ 'animalDetails.general.specialInformations.headline' | transloco}}">
                <div class="grid">
                  <div class="col-12" *ngIf="animal.specialInformation && animal.specialInformation.length">
                    <small>{{ 'animalDetails.general.specialInformations.specialInformation' | transloco }}</small>
                    <p [innerHTML]="animal.specialInformation"></p>
                  </div>
                  <div class="col-2">
                    <small>{{ 'animalDetails.general.specialInformations.castrated' | transloco }}</small>
                    <p *ngIf="animal.castrated">{{ 'yes' | transloco }}</p>
                    <p *ngIf="!animal.castrated">{{ 'no' | transloco }}</p>
                  </div>
                  <div class="col-5" *ngIf="animal.castratedDate">
                    <small>{{ 'animalDetails.general.specialInformations.castratedDate' | transloco }}</small>
                    <p>{{ animal.castratedDate | date: 'dd.MM.yyyy' }}</p>
                  </div>
                  <div class="col-5">
                    <small>{{ 'animalDetails.general.specialInformations.chemicalCastratedDate' | transloco }}</small>
                    <p>{{ animal.chemicalCastratedDate | date: 'dd.MM.yyyy' }}</p>
                  </div>
                  <div class="col-4" *ngIf="animal.sterilizedDate">
                    <small>{{ 'animalDetails.general.specialInformations.sterilizedDate' | transloco }}</small>
                    <p>{{ animal.sterilizedDate | date: 'dd.MM.yyyy' }}</p>
                  </div>
                  <div class="col-12"  *ngIf="animal.castratedNote">
                    <small>{{ 'animalDetails.general.specialInformations.castratedNote' | transloco }}</small>
                    <p>{{ animal.castratedNote }}</p>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
        <div class="grid">
          <div class="col-12">
            <p-accordion [multiple]="true" [activeIndex]="[0, 1]">
              <p-accordionTab header="{{ 'animalDetails.general.websiteInformations.headline' | transloco}}">
                <div class="grid">
                  <div class="col-3">
                    <label class="py-1" for="syncWithWebsite">{{ 'animalAdd.general.websiteInformations.syncWithWebsite' | transloco }}</label>
                    <p-toggleButton
                      id="syncWithWebsite"
                      [(ngModel)]="animal.syncWithWebsite" 
                      onLabel="{{ 'yes' | transloco }}" 
                      offLabel="{{ 'no' | transloco }}"
                      [disabled]="true"
                    />
                  </div>
                  <div class="col-2">
                    <label class="py-1" for="sanctuary">{{ 'animalAdd.general.websiteInformations.sanctuary' | transloco }}</label>
                    <p-toggleButton
                      id="sanctuary"
                      [(ngModel)]="animal.sanctuary"
                      onLabel="{{ 'yes' | transloco }}"
                      offLabel="{{ 'no' | transloco }}"
                      [disabled]="true"
                    />
                  </div>
                  <div class="col-7" *ngIf="animal.externalShortDescription">
                    <small>{{ 'animalDetails.general.websiteInformations.externalShortDescription' | transloco }}</small>
                    <p>{{ animal.externalShortDescription }}</p>
                  </div>
                  <div class="col-12"  *ngIf="animal.externalDescription">
                    <small>{{ 'animalDetails.general.websiteInformations.externalDescription' | transloco }}</small>
                    <p [innerHTML]="animal.externalDescription"></p>
                  </div>
                  <div class="col-6" *ngIf="animal.internalDetails.length">
                    <small>{{ 'animalDetails.general.specialInformations.characteristics.internal' | transloco }}</small>
                    <p>
                      <span *ngFor="let characteristic of animal.internalDetails; let isLast=last">
                        {{ 'animal.characteristics.' + getCharacteristicsEnumKey(characteristic) | transloco }}
                        <ng-container *ngIf="!isLast">,</ng-container>
                      </span>
                    </p>
                  </div>
                  <div class="col-6" *ngIf="animal.externalDetails.length">
                    <small>{{ 'animalDetails.general.specialInformations.characteristics.external' | transloco }}</small>
                    <p>
                      <span *ngFor="let characteristic of animal.externalDetails; let isLast=last">
                        {{ 'animal.characteristics.' + getCharacteristicsEnumKey(characteristic) | transloco }}
                        <ng-container *ngIf="!isLast">,</ng-container>
                      </span>
                    </p>
                  </div>
                  <div class="col-6"  *ngIf="animal.hints.length">
                    <small>{{ 'animalDetails.general.specialInformations.hints' | transloco }}</small>
                    <p>
                      <span *ngFor="let hint of animal.hints; let isLast=last">
                        {{ 'animal.hint.' + getAnimalHintKey(hint) | transloco }}
                        <ng-container *ngIf="!isLast">,</ng-container>
                      </span>
                    </p>
                  </div>
                  <div class="col-12" *ngIf="animal.websiteMetaDescription?.length">
                    <small class="py-1" for="websiteMetaDescription">
                      {{ 'animalAdd.general.websiteInformations.websiteMetaDescription' | transloco }}
                    </small>
                    <p>{{ animal.websiteMetaDescription }}</p>
                  </div>
                  <div class="col-12" *ngIf="animal.websiteMetaTitle?.length">
                    <small class="py-1" for="websiteMetaTitle">
                      {{ 'animalAdd.general.websiteInformations.websiteMetaTitle' | transloco }}
                    </small>
                    <p>{{ animal.websiteMetaTitle }}</p>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'animalDetails.origin.headline' | transloco}}">
      <div class="grid">
        <div class="col-6 mt-7">
          <app-animal-details-origin [animal]="animal"></app-animal-details-origin>
        </div>
        <div class="col-6">
          <app-animal-details-whereabouts  [animal]="animal"></app-animal-details-whereabouts>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'animalDetails.images.headline' | transloco}}">
      <app-animal-details-images [animal]="animal"></app-animal-details-images>
    </p-tabPanel>
    <p-tabPanel header="{{ 'animalDetails.timeline.headline' | transloco}}">
      <app-animal-details-timeline #animalDetailsTimelineComponent [animal]="animal"></app-animal-details-timeline>
    </p-tabPanel>
  </p-tabView>
  <ng-container *ngIf="showEditDialog">
    <app-animal-edit-dialog [animal]="animal"></app-animal-edit-dialog>
  </ng-container>
</div>
