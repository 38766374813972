<div class="card">
  <div class="p-4 text-xl font-semibold"> {{ 'animalDetails.timeline.headline' | transloco}} </div>
  <p-timeline
    [value]="events"
    align="alternate"
    styleClass="customized-timeline"
  >
    <ng-template pTemplate="marker" let-event>
      <div class="icon-wrapper" [ngStyle]="{'background-color': event.backgroundColor}">
        <i [ngClass]="event.icon"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="content" let-event>
      <p-card [header]="event.status" [subheader]="event.date">
        <img
          *ngIf="event.image"
          [src]="event.image.base64"
          [alt]="event.name"
          width="200"
          class="shadow-2"
        />
        <div *ngIf="event.imageId" class="w-10rem h-10rem relative">
          <app-animal-image-container [animal]="animal" [id]="event.imageId" [variant]="'app-preview'"></app-animal-image-container>
        </div>
        <p [innerHTML]="event.data"></p>
      </p-card>
    </ng-template>
  </p-timeline>
  <p-scrollTop styleClass="custom-scrolltop" [threshold]="500" icon="pi pi-arrow-up"></p-scrollTop>
</div>
