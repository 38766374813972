<div id="navigation">
  <p-menubar [model]="items">
    <ng-template pTemplate="start">
      <img src="./assets/layout/images/Logo_Header.svg" alt="Logo" routerLink="/" class="cursor-pointer">
    </ng-template>
    <ng-template pTemplate="end">
      <div class="grid gap-3 align-items-center">
        <div class="flex">
          <app-client-switcher></app-client-switcher>
        </div>
        <div class="flex">
          <app-profile-icon></app-profile-icon>
        </div>
      </div>
    </ng-template>
  </p-menubar>
</div>
