import {Component} from '@angular/core';
import {WaitingDialogService} from '../../services/waiting-dialog.service';

@Component({
  selector: 'app-waiting-dialog',
  templateUrl: './waiting-dialog.component.html',
  styleUrls: ['./waiting-dialog.component.scss']
})
export class WaitingDialogComponent {
  isWaiting: boolean = false;

  constructor(
    private _waitingDialogService: WaitingDialogService
  ) {
    this._waitingDialogService.showWaitingDialogSubject.subscribe((show: boolean) => {
      this.isWaiting = show;
    });
  }
}
