import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {LayoutService} from '../../layout.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  items: MenuItem[] = [];

  constructor(private layoutService: LayoutService) {
  }

  ngOnInit() {
    this.items = this.layoutService.getMenuItems();
  }
}