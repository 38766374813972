import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AnimalRepository } from "./states/animal.repository";
import { AnimalService } from "./services/animal.service";
import { Observable, Subscription, of, takeWhile, take } from "rxjs";
import { AnimalModel } from "./models/animal.model";
import { AnimalAddService } from "./services/animal-add.service";
import { AnimalFilter } from "./animal-filters/animal-filter.model";
import { AnimalImagesRepository } from "./states/animal-images.repository";

@Component({
  selector: 'app-animal-overview',
  templateUrl: './animal-overview.component.html',
  styleUrls: ['./animal-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnimalOverviewComponent implements OnInit, OnDestroy {
  alive: boolean = true;
  animals: AnimalModel[] = [];
  filteredAnimals: AnimalModel[] = [];
  selectedFilterSubscription: Subscription;
  layout: "list" | "grid" = 'grid';
  categoryFilters: AnimalFilter[] = [];
  searchTerm: string = '';
  rowCount: number = 3;
  addDialogVisible: boolean = false;

  constructor(
    private _animalRepository: AnimalRepository,
    private _animalAddService: AnimalAddService,
    private _animalService: AnimalService
  ) {
    this.selectedFilterSubscription = this._animalService.selectedFilters$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.filterAnimals();
    });
  }

  ngOnInit() {
    this._animalAddService.addDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.addDialogVisible = openModal;
    });
    this._animalRepository.getAll().pipe(takeWhile(() => this.alive)).subscribe((animals: AnimalModel[]) => {
      this.animals = animals;
      this.filterAnimals();
      this.categoryFilters = this._animalService.getFilterCategories();
    });
  }

  changeLayout(event: any) {
    switch (event.layout) {
      case 'grid':
        this.rowCount = 3;
        break;
      case 'list':
        this.rowCount = 10;
        break;
    }
  }

  filterAnimals(): void {
    if (this._animalService.selectedFilters.length > 0) {
      this.filteredAnimals = this.animals.filter(animal =>
        this._animalService.selectedFilters.some(filter => animal.category?.toLowerCase() == filter.id.toLowerCase())
      );
    } else {
      this.filteredAnimals = this.animals;
    }

    if (this.searchTerm !== '') {
      this.searchTerm = this.searchTerm.toLowerCase();
      this.filteredAnimals = this.filteredAnimals.filter(animal =>
        animal.name?.toLowerCase().includes(this.searchTerm) ||
        animal.bookNumber?.toLowerCase().includes(this.searchTerm) ||
        animal.oldBookNumber?.toLowerCase().includes(this.searchTerm)
      );
    }
  }

  handleSearch(event: Event): void {
    if (!event.target) {
      return;
    }

    this.searchTerm = (event.target as HTMLInputElement).value;
    this.filterAnimals();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.selectedFilterSubscription.unsubscribe();
  }

  toggleAddDialog() {
    this._animalAddService.toggleAddDialog(true);
  }
}
