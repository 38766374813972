<ng-container *ngIf="imageUrl?.length; else noImage">
  <span class="image-wrapper"
    style="background-image: url('{{ imageUrl }}');"
  >
    <div class="icon-website-wrapper" *ngIf="preview && animal?.syncWithWebsite && iconBadge">
      <i class="pi pi-desktop"
        pTooltip="{{ 'animalOverview.syncWithWebsite' | transloco }}"
        tooltipPosition="top"
      ></i>
    </div>
  </span>
  </ng-container>
  <ng-template #noImage>
    <span class="image-wrapper" [style.background-image]="'url(' + getFallbackImage() + ')'" [style.background-color]="'#a28f6e'">
    <div class="icon-website-wrapper" *ngIf="preview && animal?.syncWithWebsite && iconBadge">
      <i
        class="pi pi-desktop"
        pTooltip="{{ 'animalOverview.syncWithWebsite' | transloco }}" 
        tooltipPosition="top"
      ></i>
    </div>
  </span>
  </ng-template>