import {NgModule} from '@angular/core';
import {AnimalOverviewComponent} from "./animal-overview.component";
import {SharedModule} from "../../shared/shared.module";
import {AnimalFiltersComponent} from "./animal-filters/animal-filters.component";
import {DataViewModule} from "primeng/dataview";
import {RatingModule} from "primeng/rating";
import {TagModule} from "primeng/tag";
import {ImageModule} from "primeng/image";
import {MultiSelectModule} from "primeng/multiselect";
import {InputTextModule} from "primeng/inputtext";
import {DividerModule} from "primeng/divider";
import {AnimalDetailsComponent} from "./animal-details/animal-details.component";
import {TabMenuModule} from "primeng/tabmenu";
import {TabViewModule} from "primeng/tabview";
import {SkeletonModule} from "primeng/skeleton";
import {GalleriaModule} from "primeng/galleria";
import {InputSwitchModule} from "primeng/inputswitch";
import {AnimalDetailsOriginComponent} from "./animal-details-origin/animal-details-origin.component";
import {AnimalDetailsImagesComponent} from "./animal-details-images/animal-details-images.component";
import {AnimalSexIconComponent} from "./animal-sex-icon/animal-sex-icon.component";
import {AnimalEditDialogComponent} from "./animal-edit-dialog/animal-edit-dialog.component";
import {DialogModule} from "primeng/dialog";
import {AnimalCurrentCategoryComponent} from "./animal-current-category/animal-current-category.component";
import {ReactiveFormsModule} from "@angular/forms";
import {ToggleButtonModule} from "primeng/togglebutton";
import {CalendarModule} from "primeng/calendar";
import {AnimalAddDialogComponent} from "./animal-add-dialog/animal-add-dialog.component";
import {StepsModule} from "primeng/steps";
import {RadioButtonModule} from "primeng/radiobutton";
import {CheckboxModule} from "primeng/checkbox";
import {InputMaskModule} from "primeng/inputmask";
import {FileUploadModule} from "primeng/fileupload";
import {EditorModule} from "primeng/editor";
import { AnimalDetailsTimelineComponent } from './animal-details-timeline/animal-details-timeline.component';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { AnimalDetailsWhereaboutsComponent } from './animal-details-whereabouts/animal-details-whereabouts.component';
import {ConfirmDialogModule} from "primeng/confirmdialog";

@NgModule({
  imports: [
    SharedModule,
    DataViewModule,
    RatingModule,
    TagModule,
    ImageModule,
    MultiSelectModule,
    InputTextModule,
    DividerModule,
    TabMenuModule,
    TabViewModule,
    SkeletonModule,
    GalleriaModule,
    InputSwitchModule,
    DialogModule,
    ReactiveFormsModule,
    ToggleButtonModule,
    CalendarModule,
    StepsModule,
    RadioButtonModule,
    CheckboxModule,
    InputMaskModule,
    FileUploadModule,
    EditorModule,
    TimelineModule,
    CardModule,
    ConfirmDialogModule
  ],
  declarations: [
    AnimalOverviewComponent,
    AnimalFiltersComponent,
    AnimalDetailsComponent,
    AnimalDetailsOriginComponent,
    AnimalDetailsImagesComponent,
    AnimalSexIconComponent,
    AnimalAddDialogComponent,
    AnimalEditDialogComponent,
    AnimalCurrentCategoryComponent,
    AnimalDetailsTimelineComponent,
    AnimalDetailsWhereaboutsComponent
  ],
  exports: [
    AnimalAddDialogComponent,
    AnimalCurrentCategoryComponent,
    AnimalSexIconComponent,
  ],
})
export class AnimalsModule {
}
