import {Component} from '@angular/core';
import {MenuItem} from "primeng/api";
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  items: MenuItem[];
  home: MenuItem;

  constructor(
    private layoutService: LayoutService
  ) {
    this.home = {
      icon: 'pi pi-dashboard',
      routerLink: ['/']
    };

    this.items = this.layoutService.getMenuItems();
  }
}
