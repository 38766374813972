import {Injectable} from "@angular/core";
import {Subject} from 'rxjs';
import {AnimalRepository} from "../states/animal.repository";
import {RestService} from "../../../shared/services/rest.service";
import {AnimalOriginRepository} from "../states/animal-origin.repository";
import {AnimalOriginModel} from "../models/animal-origin.model";
import {ContactModel} from "../../contact/models/contact.model";
import {Validators} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class AnimalAddService {
  addDialogVisible = new Subject<boolean>();

  constructor(
    private _animalRepository: AnimalRepository,
    private _animalOriginRepository: AnimalOriginRepository,
    private _restService: RestService,
  ) {
  }

  toggleAddDialog(open: boolean) {
    this.addDialogVisible.next(open);
  }

  saveOrigin(formGroupValues: any, animalId: number) {
    const originPayload: AnimalOriginModel | any = {
      animal: {
        id: animalId
      },
      competentAuthority: {
        id: formGroupValues.originCompetentAuthority
      },
      contact: { id: '' },
      dateOfReceipt: formGroupValues.originDateOfReceipt,
      reasonForGiving: formGroupValues.originReasonForTransfer,
      specialInformation: formGroupValues.originSpecialInformation,
      location: formGroupValues.originLocation,
      otherComments: formGroupValues.originOtherComments,
      conditionOfCare: formGroupValues.originConditionOfCare,
      nutritionalStatus: formGroupValues.originNutritionalStatus,
    };


    const contactPayload = {
      gender: formGroupValues.originPersonSalutation,
      firstName: formGroupValues.originPersonFirstName,
      lastName: formGroupValues.originPersonLastName,
      companyName: formGroupValues.originCompanyName,
      zipCode: formGroupValues.originPersonZipCode,
      city: formGroupValues.originPersonCity,
      street: formGroupValues.originPersonStreet,
      phoneNumber: formGroupValues.originPersonPhoneNumber,
      mobilePhoneNumber: formGroupValues.originPersonMobileNumber,
      email: formGroupValues.originPersonEmail,
      idCardNumber: formGroupValues.originPersonIdCardNumber,
    }

    this._restService.httpPost('/contacts', contactPayload).subscribe((contact: ContactModel) => {
      originPayload.contact.id = contact.id;

      this._animalOriginRepository.create(originPayload).subscribe(() => {
        this._animalRepository.update(animalId, {
          origins: [originPayload]
        });
      });
    });

  }

  togglePersonFields(formGroup: any, $event: any) {
    if ($event.checked === false) {
      formGroup.get('originPersonSalutation')?.setValidators([]);
      formGroup.get('originPersonFirstName')?.setValidators([]);
      formGroup.get('originPersonLastName')?.setValidators([]);
      formGroup.get('originPersonZipCode')?.setValidators([]);
      formGroup.get('originPersonCity')?.setValidators([]);
      formGroup.get('originPersonStreet')?.setValidators([]);
      formGroup.get('originPersonCompanyName')?.setValidators([]);
    } else {
      formGroup.get('originPersonSalutation')?.setValidators([Validators.required]);
      formGroup.get('originPersonFirstName')?.setValidators([Validators.required]);
      formGroup.get('originPersonLastName')?.setValidators([Validators.required]);
      formGroup.get('originPersonZipCode')?.setValidators([Validators.required]);
      formGroup.get('originPersonCity')?.setValidators([Validators.required]);
      formGroup.get('originPersonStreet')?.setValidators([Validators.required]);
      formGroup.get('originPersonCompanyName')?.setValidators([Validators.required]);
    }

    formGroup.get('originPersonSalutation')?.updateValueAndValidity();
    formGroup.get('originPersonFirstName')?.updateValueAndValidity();
    formGroup.get('originPersonLastName')?.updateValueAndValidity();
    formGroup.get('originPersonZipCode')?.updateValueAndValidity();
    formGroup.get('originPersonCity')?.updateValueAndValidity();
    formGroup.get('originPersonStreet')?.updateValueAndValidity();
    formGroup.get('originPersonCompanyName')?.updateValueAndValidity();

    return formGroup;
  }
}