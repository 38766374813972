import {createStore} from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  setEntities,
  withActiveId,
  selectEntity
} from '@ngneat/elf-entities';
import {withRequestsCache, withRequestsStatus} from '@ngneat/elf-requests';
import {Injectable} from "@angular/core";
import {CompetentAuthorityModel} from "../models/competent-authority.model";
import { RestService } from '../services/rest.service';
import { take } from 'rxjs';


const storeName = 'competent-authorities';

export const store = createStore(
  {name: storeName},
  withEntities<CompetentAuthorityModel>(),
  withActiveId(),
  withRequestsCache<typeof storeName>(),
  withRequestsStatus<typeof storeName>()
);

@Injectable({providedIn: 'root'})
export class CompetentAuthoritiesRepository {
  competentAuthorities$ = store.pipe(selectAllEntities());

  constructor(
    private _restService: RestService
  ) {
    store.reset();
  }

  loadCompetentAuthorities() {
    this._restService.httpGet('/competent_authorities').pipe(take(1)).subscribe(competentAuthorities => {
      this.setCompetentAuthorities(competentAuthorities);
    });
  }

  getCompetentAuthorities() {
    return store.pipe(selectAllEntities());
  }

  getComponentAuthorityById(id: number) {
    return store.pipe(selectEntity(id));
  }

  setCompetentAuthorities(competentAuthorityModels: CompetentAuthorityModel[]) {
    store.update(setEntities(competentAuthorityModels));
  }
}