import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, filter, share} from 'rxjs';
import {environment} from "../../../environment/environment";
import {ToastService} from "./toast.service";
import {Router} from "@angular/router";
import {User, UserRepository} from "../../modules/user/states/user.repository";
import {AnimalSheltersRepository} from "../../modules/animals/states/animal-shelter.repository";
import {WaitingDialogService} from './waiting-dialog.service';
import {TokenService} from './token.service';
import {RestService} from "./rest.service";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLoggedInSubject = new BehaviorSubject<boolean>(false);
  public isLoggedIn$ = this.isLoggedInSubject.asObservable();
  private accessToken$$ = new BehaviorSubject<string | null>(null);

  private currentUserSubject = new BehaviorSubject<User | null>(null);
  currentUser$ = this.currentUserSubject.asObservable().pipe(filter(user => user !== null));
  loggedInUser: User | null = null;

  accessToken$ = this.accessToken$$.pipe(share());

  constructor(
    private router: Router,
    private _http: HttpClient,
    private _toastService: ToastService,
    private _usersRepository: UserRepository,
    private _animalSheltersRepository: AnimalSheltersRepository,
    private _waitingDialogService: WaitingDialogService,
    private _tokenService: TokenService,
    private _restService: RestService
  ) {
    this.loadToken();
  }

  login(password: string) {
    this._http
      .post<{ token: string }>(environment.apiUrl + '/login', {"pin": password})
      .subscribe(response => {
        this._tokenService.setTokens(response);
        this.loadUser();

        this.isLoggedInSubject.next(true);
        this._toastService.showSuccess('Login erfolgreich');
        this._waitingDialogService.showWaitingDialog(false);
      }, () => {
        this.isLoggedInSubject.next(false);
        this._toastService.showError('Login fehlgeschlagen');
        this._waitingDialogService.showWaitingDialog(false);
      });

    this.router.navigate(['/']);

    return;
  }

  loadUser() {
    this._restService.httpGet('/users/me').subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.loggedInUser = user;
      this._usersRepository.removeAllUsers();
      this._usersRepository.addUser({
        id: user.id,
        name: user.displayName,
        token: user.token,
        roles: user.roles,
        defaultAnimalShelter: user.defaultAnimalShelter
      });

      this._usersRepository.setActiveUserId(user.id);
      this.currentUserSubject.next(this.loggedInUser);
      this._animalSheltersRepository.setActiveAnimalShelter(user.defaultAnimalShelter);
    });
  }

  loadToken() {
    const token = this._tokenService.getAccessToken();
    if (token) {
      this.isLoggedInSubject.next(true);
    } else {
      this.isLoggedInSubject.next(false);
    }
  }

  logout() {
    this._tokenService.clearToken();
    this.isLoggedInSubject.next(false);
    this.router.navigate(['/login']);
  }
}