import {Injectable} from "@angular/core";
import {CompetentAuthoritiesRepository} from "../states/competent-authorities.repository";
import {CompetentAuthorityModel} from "../models/competent-authority.model";
import {RestService} from "./rest.service";
import {map, Observable, take} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CompetentAuthoritiesService {

  constructor(
    private _competentAuthoritiesRepository: CompetentAuthoritiesRepository,
    private _restService: RestService
  ) {
  }

  getCompetentAuthorities() {
    this._restService.httpGet('/competent_authorities').subscribe((competentAuthorities: CompetentAuthorityModel[]) => {
      this._competentAuthoritiesRepository.setCompetentAuthorities(competentAuthorities);

      return competentAuthorities;
    });

    return [];
  }

  getCompetentAuthoritiesByShelterId(shelterId: number): Observable<CompetentAuthorityModel[]> {
    return this._competentAuthoritiesRepository.getCompetentAuthorities().pipe(
      take(1),
      map((competentAuthorities: CompetentAuthorityModel[]) => {
        const filteredCompetentAuthorities = competentAuthorities.filter((competentAuthority: CompetentAuthorityModel) => {
          return competentAuthority.animalShelter.id === shelterId;
        });
        return filteredCompetentAuthorities;
      })
    );
  }
}