import {NgModule} from '@angular/core';
import type {Routes} from '@angular/router';
import {RouterModule} from '@angular/router';
import {AuthGuard} from "./shared/auth/auth.guard";
import {LoginComponent} from "./modules/login/login.component";
import {UserRoutes} from "./modules/user/user.routes";
import {LayoutComponent} from './layout/components/layout/layout.component';
import {AnimalRoutes} from "./modules/animals/animals.routes";
import { DashboardComponent } from './modules/dashboard/dashboard.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '', component: LayoutComponent, canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: DashboardComponent },
      ...UserRoutes,
      ...AnimalRoutes,
    ]
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
