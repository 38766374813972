import {createStore} from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  setEntities,
  updateEntities,
  withActiveId,
  selectEntity,
  upsertEntities,
  deleteEntities
} from '@ngneat/elf-entities';
import {withRequestsCache, withRequestsStatus} from '@ngneat/elf-requests';
import {Injectable} from "@angular/core";
import {AnimalModel} from "../models/animal.model";
import {RestService} from "../../../shared/services/rest.service";
import {AnimalImageModel} from "../models/animal-image.model";
import { BehaviorSubject, finalize, map, Observable, of, switchMap, take, tap } from "rxjs";
import {AnimalRepository} from "./animal.repository";
import {environment} from "../../../../environment/environment";
import {HttpClient} from "@angular/common/http";
import { TranslocoService } from '@ngneat/transloco';
import { ToastService } from 'src/app/shared/services/toast.service';

const storeName = 'animal-images';

export const store = createStore(
  {name: storeName},
  withEntities<AnimalImageModel>(),
  withActiveId(),
  withRequestsCache<typeof storeName>(),
  withRequestsStatus<typeof storeName>()
);

@Injectable({providedIn: 'root'})
export class AnimalImagesRepository {
  private imageAddedSubject = new BehaviorSubject<AnimalImageModel | null>(null);
  imageAdded$ = this.imageAddedSubject.asObservable();

  constructor(
    private _restService: RestService,
    private _animalRepository: AnimalRepository,
    private _http: HttpClient,
    private _translateService: TranslocoService,
    private _toast: ToastService
  ) {
  }

  setImages(animalImages: AnimalImageModel[]) {
    store.update(setEntities(animalImages));
  }

  getImages(animalId: number): Observable<AnimalImageModel[]> {
    return this._animalRepository.animals$.pipe(
      take(1),
      switchMap((animals: AnimalModel[]) => {
        const animal = animals.find(a => a.id === animalId);
        if (animal && animal.images.length) {
          return store.pipe(
            selectAllEntities(),
            take(1),
            map((existingImages: AnimalImageModel[]) => {
              // Bilder, die bereits im Store sind und zu dem aktuellen Tier gehören
              const cachedImages = existingImages.filter(img => animal.images.some(ai => ai.id === img.id));
              const cachedImageIds = cachedImages.map(img => img.id);
              // Neue Bilder, die noch nicht im Store sind
              const newImages = animal.images.filter(img => !cachedImageIds.includes(img.id));

              if (newImages.length > 0) {
                store.update(setEntities(newImages));
              }

              return cachedImages.concat(newImages);
            })
          );
        } else {
          return of([]);
        }
      })
    );
  }


  updateImage(animalId: number, imageId: AnimalImageModel['id'], image: Partial<AnimalImageModel>) {
    this._restService.httpPut('/animals/images/' + imageId, image).subscribe(() => {
      store.update(updateEntities(imageId, image));
      this._animalRepository.getById(animalId);
      this._toast.showSuccess(this._translateService.translate('toast.saveSuccess'));

    });

    return store.pipe(selectEntity(imageId));
  }

  getImageById(id: number): Observable<AnimalImageModel | null> {
    return store.pipe(
      selectEntity(id),
      take(1),
      switchMap((image: AnimalImageModel | undefined) => {
        if (!image) {
          return of(null);
        }
        return of(image);
      })
    );
  }

  loadVariantBase64(imageId: number, variantKey: string): Observable<AnimalImageModel | null> {
    return store.pipe(
      selectEntity(imageId),
      take(1),
      switchMap((image: AnimalImageModel | undefined) => {
        if (!image) {
          return of(null);
        }

        const variant = image.variants[variantKey];
        const urlToFetch = variant?.url || image.contentUrl;
        if (!variant.base64) {
          return this.getImageAsBase64(urlToFetch).pipe(
            map((base64: string) => {
              variant.base64 = base64;
              store.update(upsertEntities([image]));
              return image;
            })
          );
        } else {
          return of(image);
        }
      })
    );
  }



  addImage(image: FormData): Observable<any> {
    return this._restService.httpPost('/animals/images', image).pipe(
      tap((data: any) => {
        const variant = data.variants['full'];
        const urlToFetch = variant?.url || data.contentUrl;
        this.getImageAsBase64(urlToFetch).subscribe((base64: string) => {
          data.variants['full'].base64 = base64;
          store.update(upsertEntities([data]));
          this.imageAddedSubject.next(data);
        });
      })
    );
  }

  clearImageAdded() {
    this.imageAddedSubject.next(null);
  }

  deleteImage(selectedImageId: number) {
    return this._restService.httpDelete('/animals/images/' + selectedImageId).pipe(
      finalize(() => {
        store.update(deleteEntities(selectedImageId));
      })
    );
  }

  getImageAsBase64(imagePath: string): Observable<string> {
    const url = environment.apiUrl.replace(new RegExp('/api$'), '') + imagePath;

    return new Observable<string>((observer) => {
      this._http.get(url, { responseType: 'blob' }).subscribe({
        next: (blob: Blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            observer.next(reader.result as string);
            observer.complete();
          };
          reader.onerror = (error) => {
            observer.error(error);
          };
          reader.readAsDataURL(blob);
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }
}
