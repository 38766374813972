import { Route } from '@angular/router';
import {ProfileComponent} from "./profile/profile.component";
import {AuthGuard} from "../../shared/auth/auth.guard";

export const UserRoutes: Route[] = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  }
];
