import { Injectable } from '@angular/core';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class ToastService {


  constructor(private messageService: MessageService) {

  }

  showSuccess(message: string) {
    this.messageService.add({severity: 'success', summary: 'Erfolgreich', detail: message});
  }

  showError(message: string) {
    this.messageService.add({severity: 'error', summary: 'Fehler', detail: message});
  }
}
