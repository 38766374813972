<div class="flex flex-row flex-wrap justify-content-between animal-overview-filters">
  <div class="flex xs:col-12 md:col flex-wrap md:flex-nowrap align-items-end md:flex-none p-0">
    <ng-container *ngFor="let filter of typeFilters">
      <div
        class="flex justify-content-center align-items-end ml-1 mb-1"
        style="min-width: 88px"
      >
        <p-button
          [ngClass]="{ selected: selectedFilters.includes(filter.id) }"
          (click)="
            selectedFilters.includes(filter.id)
              ? onFilterDeselect(filter)
              : onFilterSelect(filter)
          "
          styleClass="p-button p-button-text btn-filter"
        >
          <div class="flex flex-column">
            <div class="flex-1 animal-icon">
              <p-image
                class="block mx-1"
                src="{{ filter.imgSrc }}"
                width="37"
                [imageStyle]="{ 'max-height': '30px' }"
              ></p-image>
            </div>
            <div class="flex-1">
              <p
                class="m-0 block font-semibold text-color-secondary font-semibold"
              >
                {{ filter.title }}
              </p>
            </div>
          </div>
        </p-button>
      </div>
    </ng-container>
  </div>
  <div class="col flex flex-none justify-content-end">
    <div class="flex flex-none align-items-center col">
      <span class="p-input-icon-right w-full">
        <i class="pi pi-search"></i>
        <input
          class="w-full"
          type="text"
          placeholder="Suche"
          pInputText
          (input)="searchEvent.emit($event)"
        />
      </span>
    </div>
    <div class="col flex-none flex align-items-center">
      <p-dataViewLayoutOptions #layoutOptions></p-dataViewLayoutOptions>
    </div>
  </div>
</div>
