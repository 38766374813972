import {Injectable} from "@angular/core";
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnimalEditService {
  editDialogVisible = new BehaviorSubject<{ [tab: string]: boolean | string }>({});

  toggleEditDialog(openTab: { [tab: string]: boolean | string }) {
    this.editDialogVisible.next(openTab);
  }
}