import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitingDialogService {
  showWaitingDialogSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() { }

  showWaitingDialog(show: boolean) {
    this.showWaitingDialogSubject.next(show);
  }
}