import {Component, Input, OnInit} from '@angular/core';
import {AnimalService} from "../services/animal.service";
import {AnimalModel} from "../models/animal.model";
import {AnimalFilter} from "../animal-filters/animal-filter.model";

@Component({
  selector: 'app-animal-current-category',
  templateUrl: './animal-current-category.component.html',
  styleUrls: ['./animal-current-category.component.scss']
})
export class AnimalCurrentCategoryComponent implements OnInit {
  @Input() animal: AnimalModel | undefined;
  @Input() type: string | undefined;
  @Input() showIcon: boolean = true;
  filters: AnimalFilter[] = [];

  constructor(
    private _animalService: AnimalService
  ) {
  }

  ngOnInit(): void {
    this.filters = this._animalService.getFilterCategories();
  }
}