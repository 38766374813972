import {TranslocoService} from '@ngneat/transloco';
import {Injectable} from '@angular/core';
import { ContactModel } from 'src/app/modules/contact/models/contact.model';

@Injectable({
  providedIn: 'root'
})

export class HelperService {

  constructor(
    private _translateService: TranslocoService,
  ) {
  }

  getEnumOptions<T extends Record<string, string>, U extends {
    name: string,
    value: string
  }>(enumVariable: T, translationKey: string): U[] {
    const options: U[] = [];

    options.push({name: '', value: null as unknown} as U);

    for (const key in enumVariable) {
      if (Object.prototype.hasOwnProperty.call(enumVariable, key)) {
        const name = this._translateService.translate(`${translationKey}.${key}`);
        const value = enumVariable[key].toLowerCase();

        options.push({name, value} as U);
      }
    }

    return options;
  }

  getDateStringByLang(): string {
    if (this._translateService.getActiveLang() === 'en') {
      return 'yyyy-MM-dd';
    } else {
      return 'dd.MM.yyyy';
    }
  }

  getSalutationOptions(): {name: string, value: string | null}[] {
    return [
      {name: this._translateService.translate('salutation.none'), value: null},
      {name: this._translateService.translate('salutation.male'), value: 'male'},
      {name: this._translateService.translate('salutation.female'), value: 'female'},
      {name: this._translateService.translate('salutation.diverse'), value: 'other'},
      {name: this._translateService.translate('salutation.company'), value: 'company'}
    ]
  }

  checkAnyContactData(contact: ContactModel): boolean {
    if (contact === null) {
      return false;
    }
  
    // Liste der zu ignorierenden Eigenschaften
    const propertiesToIgnore: (keyof ContactModel)[] = ['id', 'gender'];
  
    // Überprüfen, ob irgendeine Eigenschaft außer 'id' und 'gender' gefüllt ist
    return Object.keys(contact).some((key) => 
      !propertiesToIgnore.includes(key as keyof ContactModel) && contact[key as keyof ContactModel] !== null && contact[key as keyof ContactModel] !== ''
    );
  }

  normalizeDate(date: Date): Date {
    // Entfernt den Zeitzonen-Offset, um das Datum zu normalisieren
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }
}