<div class="layout-container layout-topbar-atd layout-menu-horizontale layout-top-large">
  <div class="layout-top">
    <app-navigation></app-navigation>

    <div class="layout-topbar-separator"></div>

    <app-breadcrumb></app-breadcrumb>
  </div>

  <div class="layout-content">
    <router-outlet></router-outlet>
  </div>
</div>
