import {NgModule} from '@angular/core';
import {SharedModule} from "../../shared/shared.module";
import {SkeletonModule} from "primeng/skeleton";
import {NgOptimizedImage} from "@angular/common";
import { ChartModule } from 'primeng/chart';
import { AnimalsModule } from '../animals/animals.module';
import { DashboardComponent } from './dashboard.component';
import {DataViewModule} from "primeng/dataview";
import {ChipModule} from "primeng/chip";
import {TagModule} from "primeng/tag";

@NgModule({
  imports: [
    SharedModule,
    SkeletonModule,
    NgOptimizedImage,
    ChartModule,
    AnimalsModule,
    DataViewModule,
    ChipModule,
    TagModule
  ],
  declarations: [
    DashboardComponent,
  ],
  exports: [],
  providers: []
})
export class DashboardModule {
}
