import {createStore} from '@ngneat/elf';
import {
  withEntities,
  setEntities,
  withActiveId,
  selectManyByPredicate,
} from '@ngneat/elf-entities';
import {withRequestsCache, withRequestsStatus} from '@ngneat/elf-requests';
import {Injectable} from "@angular/core";
import {AnimalOriginModel} from "../models/animal-origin.model";
import {RestService} from "../../../shared/services/rest.service";
import {of} from 'rxjs';
import {switchMap, tap} from "rxjs/operators";


const storeName = 'animal-origin';

export const originStore = createStore(
  {name: storeName},
  withEntities<AnimalOriginModel>(),
  withActiveId(),
  withRequestsCache<typeof storeName>(),
  withRequestsStatus<typeof storeName>()
);

@Injectable({providedIn: 'root'})
export class AnimalOriginRepository {
  constructor(
    private _restService: RestService
  ) {
    originStore.reset();
  }

  getByAnimalId(animalId: number | undefined) {
    if (animalId === undefined) {
      return of(undefined);
    }
    return originStore.pipe(
      selectManyByPredicate((entity) => (entity as AnimalOriginModel)?.animal?.id === animalId),
      switchMap(entities => {
        if (entities.length > 0) {
          return of(entities);
        } else {
          return this._restService.httpGet(`/animal_origins?animal.id=${animalId}`).pipe(
            tap(origins => {
              originStore.update(setEntities([origins]));
            })
          );
        }
      })
    );
  }

  setAll(origins: AnimalOriginModel[]) {
    originStore.update(setEntities(origins));
  }

  create(payload: any) {
    return this._restService.httpPost('/animal_origins', payload)
      .pipe(
        tap((animalOrigin: AnimalOriginModel) => {
          originStore.update(setEntities([animalOrigin]));
        })
      );
  }

  update(id: string, payload: any) {
    return this._restService.httpPut('/animal_origins/' + id, payload)
      .pipe(
        tap((animalOrigin: any) => {
          originStore.update(setEntities([animalOrigin]));
        })
      );
  }
}