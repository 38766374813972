import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'dateYear'
})
export class YearDatepipe extends DatePipe implements PipeTransform {
  constructor() {
    super('en-US'); // You can replace 'en-US' with your desired locale
  }

  override transform(value: null | undefined): null;
  override transform(value: string | number | Date, args?: any): string | null;
  override transform(value: string | number | Date | null | undefined): string | null {
    if (value === null || value === undefined) {
      return null;
    }
    return super.transform(value, "y");
  }
}