<div class="card flex flex-column justify-content-center align-items-center text-center p-4 max-w-20rem bg-green-50">
  <div class="card-header">
    <h5 class="card-title">Datei hochladen</h5>
  </div>
    <h6 class="text-xs">Erlaubte Dateitypen: .jpg, .png </h6>
  <p-fileUpload
      name="demo[]"
      [url]="uploadUrl"
      (uploadHandler)="onUpload($event)"
      [multiple]="true"
      accept=".jpg, .png, .jpeg"
      [maxFileSize]="30000000"
      [showUploadButton]="false"
      [showCancelButton]="false"
      [customUpload]="true"
      type="file"
  >
    <ng-template pTemplate="content">
      <div class="mt-1" [hidden]="isFileSelected">
        <p class="text-primary-300">Drag & Drop</p>
        <i class="pi pi-plus text-3xl text-primary-300"></i>
      </div>
    </ng-template>
    <ng-template let-file pTemplate="file" let-index="index">
      <ng-container *ngIf="index < maxFilesToShow">
        <div class="flex p-1">
          <img [src]="file.objectURL" class="p-mr-2" height="50"/>
        </div>
      </ng-container>
      <ng-container *ngIf="index === maxFilesToShow + 1">
        <div class="flex align-items-center justify-content-center p-1">
          <p class="text-primary-600">+{{ fileUpload.files.length - maxFilesToShow }}</p>
        </div>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="toolbar">
      <div class="custom-toolbar">
        <button
            pButton
            type="button"
            title="Auswählen"
            icon="pi pi-folder-open"
            class="p-button-rounded p-button-success p-mr-2"
            (click)="fileUpload.choose()"
        ></button>
        <button
            pButton
            type="button"
            title="Hochladen"
            icon="pi pi-cloud-upload"
            class="p-button-rounded p-button-warning p-mr-2"
            (click)="fileUpload.upload()"
            [disabled]="!isFileSelected"
        ></button>
        <button
            pButton
            type="button"
            title="Auswahl aufheben"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger"
            (click)="fileUpload.clear()"
            [disabled]="!isFileSelected"
        ></button>
      </div>
    </ng-template>
  </p-fileUpload>
</div>
