import {createStore} from '@ngneat/elf';
import {
  withEntities,
  setEntities,
  withActiveId,
  setActiveId,
  selectAllEntities,
} from '@ngneat/elf-entities';
import {withRequestsCache, withRequestsStatus} from '@ngneat/elf-requests';
import {Injectable} from "@angular/core";
import {AnimalShelterModel} from "../models/animal-shelter.model";
import { RestService } from 'src/app/shared/services/rest.service';
import { Observable, take } from 'rxjs';

const storeName = 'animal-shelters';

export const store = createStore(
  {name: storeName},
  withEntities<AnimalShelterModel>(),
  withActiveId(),
  withRequestsCache<typeof storeName>(),
  withRequestsStatus<typeof storeName>()
);

@Injectable({providedIn: 'root'})
export class AnimalSheltersRepository {
  constructor(
    private _restService: RestService
  ) {
    store.reset();
  }

  loadAnimalShelters() {
    this._restService.httpGet('/animal_shelters').pipe(take(1)).subscribe(shelters => {
      this.setAnimalShelters(shelters);
    });
  }

  setAnimalShelters(shelters: AnimalShelterModel[]) {
    store.update(setEntities(shelters));
  }

  setActiveAnimalShelter(entry: AnimalShelterModel) {
    if (!entry) {
      return;
    }
    store.update(setActiveId(entry.id));
  }

  getActiveAnimalShelter(): AnimalShelterModel | undefined {
    const state = store.state;
    const activeId = state.activeId;
    if (!activeId) {
      return undefined;
    }
    return state.entities[activeId] as AnimalShelterModel | undefined;
  }

  getAnimalShelters(): Observable<AnimalShelterModel[]> {
    return store.pipe(selectAllEntities());
  }
}