import {Component, Input} from '@angular/core';
import {AnimalModel} from "../models/animal.model";

@Component({
  selector: 'app-animal-sex-icon',
  templateUrl: './animal-sex-icon.component.html',
  styleUrls: ['./animal-sex-icon.component.scss']
})
export class AnimalSexIconComponent {
  @Input() animal: AnimalModel | undefined;
  @Input() color: string = 'black';
}